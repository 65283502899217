import {
    Dialog, DialogActions, DialogContent, DialogTitle,
} from "@mui/material";
import Button from "@material-ui/core/Button";
import {useEffect, useState} from "react";

const IdleTimeOutModal = ({
                              show,
                              handleClose,
                              handleLogout,
                              secondsUntilLogout,
                          }) => {
    const [logoutIn, setLogoutIn] = useState(secondsUntilLogout);
    const [timerInterval, setTimerInterval] = useState(secondsUntilLogout);
    const startTimer = () => {
        setLogoutIn(secondsUntilLogout)
        setTimerInterval(setInterval(() => {
            setLogoutIn((v) => v - 1)
        }, 1000))
    }
    const stopTimer = () => {
        if (timerInterval !== null) {
            clearInterval(timerInterval)
            setTimerInterval(null)
        }
    }
    useEffect(() => {
        if (logoutIn <= 0) {
            handleLogout()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [logoutIn])
    useEffect(() => {
        if (show === true) {
            startTimer()
        } else {
            stopTimer()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show])
    return (<Dialog disableEscapeKeyDown open={show} onClose={handleClose}>
        <DialogTitle style={{
            display: "flex",
            justifyContent: "space-between"
        }}>
            <span>Session Timeout</span>
        </DialogTitle>
        <DialogContent>
            <div>
                You are about to be logged out due to inactivity in{" "}
                {logoutIn} seconds.
            </div>
            <div>Would you like to continue your session?</div>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose}>Continue Session</Button>
            <Button onClick={handleLogout}>Logout</Button>
        </DialogActions>
    </Dialog>);
};
export default IdleTimeOutModal;

import PersistentModel from "../../../models/persistent";
import DateUtils from "../../../utils/date";

const keys = {
  CHART_SELECTED_TAB: "brushingDataPMDataSelectedTab",
  FILTER_START_DATE: "brushingDataPMFilterStartDate",
  FILTER_END_DATE: "brushingDataPMDataFilterEndDate",
  CHART_DISPLAY_TYPE: "brushingDataPMDataChartDisplayType",
  FILTER_VALUE: "brushingDataPMFilterValue",
  CHART_INTERVAL_MODE: "brushingDataPMChartIntervalMode",
  SELECTED_CHART_NAME: "brushingDataPMSelectedChartName",
};
class BrushingDataPM extends PersistentModel {
  get chartsSelectedTab() {
    const val = parseInt(this.get(keys.CHART_SELECTED_TAB));
    if (isNaN(val)) {
      return 0;
    }
    return val;
  }
  set chartsSelectedTab(value) {
    this.set(keys.CHART_SELECTED_TAB, value);
  }
  /**
   * The start date is used for filtering and should be the older date.
   * If there is no stored value for this date, caclulate the date
   * using brushing filter days value.
   */
  get startDate() {
    const milliseconds = parseInt(this.get(keys.FILTER_START_DATE));
    if (Number.isInteger(milliseconds)) {
      return new Date(milliseconds);
    }
    const date = new Date();
    date.setDate(this.getDaysBetweenFilterDates(date));
    this.startDate = date;
    return date;
  }
  set startDate(value) {
    if (value instanceof Date) {
      this.set(keys.FILTER_START_DATE, value.getTime());
    }
  }
  /**
   * The end date is used for filtering and should be the more recent date.
   * If there is no stored value for this date, use today's date.
   */
  get endDate() {
    const milliseconds = parseInt(this.get(keys.FILTER_END_DATE));
    if (Number.isInteger(milliseconds)) {
      return new Date(milliseconds);
    }
    const date = new Date();
    this.endDate = date;
    return date;
  }
  set endDate(value) {
    if (value instanceof Date) {
      this.set(keys.FILTER_END_DATE, value.getTime());
    }
  }
  get numberOfDaysInFilter() {
    return DateUtils.getDaysBetweenDates(this.endDate, this.startDate);
  }
  get chartDisplayType() {
    return this.get(keys.CHART_DISPLAY_TYPE);
  }
  set chartDisplayType(value) {
    this.set(keys.CHART_DISPLAY_TYPE, value);
  }

  get brushingsFilterValue() {
    const val = parseInt(this.get(keys.FILTER_VALUE));
    if (isNaN(val)) {
      return 7; //default
    }
    return val;
  }
  set brushingsFilterValue(value) {
    this.set(keys.FILTER_VALUE, value);
  }
  get chartIntervalMode() {
    return this.get(keys.CHART_INTERVAL_MODE);
  }
  set chartIntervalMode(value) {
    this.set(keys.CHART_INTERVAL_MODE, value);
  }
  get selectedChartName() {
    return this.get(keys.SELECTED_CHART_NAME);
  }
  set selectedChartName(value) {
    this.set(keys.SELECTED_CHART_NAME, value);
  }
  getDaysBetweenFilterDates(date) {
    return date.getDate() - (this.brushingsFilterValue - 1);
  }
  restoreDefaults() {
    for (const key in keys) {
      this.remove(keys[key]);
    }
  }
}
/* Presentation Models (PM) should only have one instance. 
Export a single instance. */
const brushingDataPM = new BrushingDataPM();
export default brushingDataPM;

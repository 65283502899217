import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Tab, Tabs, Divider } from "@mui/material";
import { withStyles } from "@material-ui/core/styles";
import appModel from "../../../models/app";
import "./details.css";
import BrushingData from "./brushingData";
import CoverageMap from "./coverageMap";
import Recommendation from "./recommendation";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Typography, ThemeProvider } from "@material-ui/core";
import { theme, styles } from "./details.styles";
import KolibreeService from "../../../services/kolibree";
import { KolibreeBrushingAdpater } from "../../../adapters/kolibree/brushings";
import { KolibreeBrushingModel } from "../../../models/kolibree/brushings";
import ErrorDialog from "../../../components/dialogs/error";
//Importing the created Dexie DB:
import db from "../../../views/patients/patient/db";
import CircularProgress from "@mui/material/CircularProgress";

class PatientDetailsPage extends Component {
  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
    let endDate = new Date();
    let startDate = new Date();
    startDate.setMonth(endDate.getMonth() - 3);
    this.state = {
      tabvalue: 0,
      dataLoaded: false,
      brushings: [],
      errorDialogMessage: null,
      errorDialogTitle: "",
    };
    this.patient = appModel.selectedPatient;
    this.handleChangeofTab = this.handleChangeofTab.bind(this);
    this.processBrushingData = this.processBrushingData.bind(this);
    this.pushtoDexieDB = this.pushtoDexieDB.bind(this);
  }

  async componentDidMount() {
    const patient = appModel.selectedPatient;
    const service = new KolibreeService();
    service.authToken = appModel.jwtToken.token;
    service.serial = patient.brushSerialNumber;
    //If cached brushings exist for given accountID, skip the Kolibree call, else call it to initialize cache
    const cachedBrushings = await db.patients.get(patient.accountID);
    if (cachedBrushings) {
      this.setState({ dataLoaded: true }, () => {
        this.processBrushingData(cachedBrushings.brushings);
      });
    } else {
      try {
        this.setState({
          dataLoaded: false,
        }, async () => {
          const brushingsResponse = await service.getBrushingsForAccount(patient.accountID);
          if (brushingsResponse.data && brushingsResponse.data.brushings) {
            this.setState({ dataLoaded: true });
            let brushings = brushingsResponse.data.brushings;
            this.pushtoDexieDB(brushings); //Pushing to cache for future use
            this.processBrushingData(brushings);
          } else {
            this.setState({
              errorDialogMessage: appModel.unableToLoadDataErrorMessage,
            });
            console.error("Failed to get brushings for account. ", brushingsResponse);
          }
        });
      } catch (e) {
        this.setState({
          errorDialogMessage: appModel.unableToLoadDataErrorMessage,
        });
        console.error("Failed to get brushings for account. ", e);
      }
    }
    this.setState({ patient: patient });
  }

  //Sets model = to converted python Kolibree model
  processBrushingData(brushings) {
    const _brushings = [];
    for (const b of brushings) {
      _brushings.push(new KolibreeBrushingModel(new KolibreeBrushingAdpater(b)));
    }
    this.setState({ brushings: _brushings });
  }

  handleChangeofTab(event, value) {
    this.setState({ tabvalue: value });
  }

  //Adds brushing data to IndexedDB via Dexie wrapper
  async pushtoDexieDB(brushings) {
    const patient = appModel.selectedPatient;
    //Did the db open successfully?
    db.open().catch(function(e) {
      console.error("Open failed: " + e.stack);
    });
    db.transaction("rw", db.patients, function() {
      db.patients.add({
        accountID: patient.accountID,
        brushings: brushings,
      });
    }).catch(function(e) {
      console.error(e.stack);
    });
  }

  render() {
    const { classes } = this.props;
    return (<ThemeProvider theme={theme}>
      <div style={{
        height: "100%",
        width: "100%",
      }} className={classes.patientdetails} id="patientdetails">
        <div className={classes.titlebar}>
          <NavLink to="/patients" color="primary">
            <Typography variant="h5">
              Patient List
              <ArrowForwardIosIcon style={{
                width: "40px",
                paddingTop: "10px",
              }} />
            </Typography>
          </NavLink>
          <Typography variant="h5" style={{ paddingTop: "7px" }}>
            Patient ID{" "}
            {this.state.patient ? (this.state.patient.patientID) : (
              <CircularProgress size="1rem" style={{ marginLeft: "1rem" }} />)}
          </Typography>
        </div>
        <div style={{ marginLeft: "-10px" }}>
          <Tabs value={this.state.tabvalue} onChange={this.handleChangeofTab} className={classes.mainTabs}
                TabIndicatorProps={{
                  style: {
                    background: "#D2010D",
                  },
                }}>
            <Tab className={classes.tab} value={0} label={<Typography variant="h2"
                                                                      className={this.state.tabvalue === 0 ? classes.selected : classes.default}>
              Brushing Data
            </Typography>} />
            <Tab className={classes.tab} value={1} label={<Typography variant="h2"
                                                                      className={this.state.tabvalue === 1 ? classes.selected : classes.default}>
              Coverage Map
            </Typography>} />
            <Tab className={classes.tab} value={2} label={<Typography variant="h2"
                                                                      className={this.state.tabvalue === 2 ? classes.selected : classes.default}>
              Recommendations
            </Typography>} />
          </Tabs>
          <Divider className={classes.divider} />
        </div>
        {this.state.tabvalue === 0 && (
          <BrushingData brushings={this.state.brushings} loading={!this.state.dataLoaded} />)}
        {this.state.tabvalue === 1 && (
          <CoverageMap brushings={this.state.brushings} loading={!this.state.dataLoaded} />)}
        {this.state.tabvalue === 2 && (
          <Recommendation brushSerialNumber={this.state.patient?.brushSerialNumber || null} />)}
      </div>
      <div className="tabletmodedetails">
        <img alt="connectpro" src={process.env.PUBLIC_URL + "/TabletRotate.png"}></img>
      </div>
      <ErrorDialog message={this.state.errorDialogMessage} open={this.state.errorDialogMessage !== null}
                   onClose={() => {
                     this.setState({ errorDialogMessage: null });
                   }} />
    </ThemeProvider>);
  }
}

export default withStyles(styles)(PatientDetailsPage);

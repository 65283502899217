import BaseModel from "../base.js";
/* Keys to strip out of values returned by brushingsResults getter. */
const excludeFromBrushingResult = ["version", "capabilities"];

export class KolibreeBrushingModel extends BaseModel {
  constructor(data = null) {
    super();
    this.id = null;
    this.accountID = null;
    this.profileID = null;
    this.dateString = "";
    this.cleanScore = null;
    this.coins = null;
    this.datetimeString = "";
    this._datetime = null;
    this.duration = null;
    this.coverage = null;
    this.quality = null;
    this.processedData = {};
    if (data !== null) {
      this.setProperties(data);
    }
  }

  /**
   * datetime of brushing
   * @type {Date}
   */
  get datetime() {
    const tempDate = new Date(this._datetime);
    const offset = tempDate.getTimezoneOffset() * 60 * 1000;
    tempDate.setTime(tempDate.getTime() + offset);
    return tempDate;
  }

  /**
   * date of brushing set to midnight
   * @type {Date}
   */
  get date() {
    const justDate = new Date(this.datetime);
    justDate.setHours(0, 0, 0, 0);
    return justDate;
  }

  /**
   * Whether brushing is in the local AM
   * @type {Boolean}
   */
  get isAM() {
    return this.datetime.getHours() < 12;
  }

  /**
   * Get coverage by zones from brushing.
   * @type {Object}
   */
  get brushingResult() {
    const zoneNames = Object.keys(this.processedData).filter((key) => {
      return !excludeFromBrushingResult.includes(key);
    });
    const coveragesByZone = {};
    for (const zoneName of zoneNames) {
      const zone = this.processedData[zoneName];
      let effectiveTimeForZone = 0;
      if (Array.isArray(zone.passes)) {
        for (const pass of zone.passes) {
          effectiveTimeForZone += pass.effective_time;
        }
      }
      if (effectiveTimeForZone > 0) {
        coveragesByZone[zoneName] = Math.min(
          Math.floor((effectiveTimeForZone / zone.expected_time) * 100),
          100
        );
      } else {
        coveragesByZone[zoneName] = 0;
      }
    }
    return coveragesByZone;
  }
}

import React from "react";
import "./homepage.css";
import PropTypes from "prop-types";
import { withRouter, Redirect } from "react-router-dom";
import appModel from "../models/app";
import { Grid } from "@mui/material";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  connectPro: {
    position: "relative",
    left: "13vw",
    bottom: "60vh",
    [theme.breakpoints.down("xl")]: {
      width: "40%",
    },
  },
  colgateLogo: {
    position: "relative",
    bottom: "70vh",
    [theme.breakpoints.down("xl")]: {
      width: "20%",
    },
  },
  footer: {
    backgroundColor: "#811C1A",
    color: "#ffff",
    position: "absolute",
    fontFamily: "Roboto, sans-serif",
    fontSize: "16px",
    lineHeight: "19px",
    left: 0,
    bottom: 0,
    right: 0,
  },
});
class PasswordReset extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  componentDidMount() {
    window.gigya.accounts.showScreenSet({
      screenSet: "Pro-Portal-RegistrationLogin",
      startScreen: "Pro_Portal_PasswordReset",
      containerID: "auth-container",
      onAfterSubmit: ({ response }) => {
        this.props.onLoginResponse(response);
      },
    });
  }

  render() {
    if (appModel.isLoggedIn) {
      return (
        <Redirect
          to={{ pathname: "/patients", state: { from: this.props.location } }}
        />
      );
    }
    const { classes } = this.props;
    return (
      <Grid container>
        <Grid item md={5} xl={5}>
          <img
            alt="simple-img"
            src={process.env.PUBLIC_URL + "/LoginImage.png"}
            style={{ height: "96vh", width: "100%", objectFit: "fill" }}
          ></img>
          <img
            alt="connectpro"
            className={classes.connectPro}
            src={process.env.PUBLIC_URL + "/ConnectPro.png"}
          ></img>
          <img
            alt="colgate_logo"
            className={classes.colgateLogo}
            src={process.env.PUBLIC_URL + "/Logo _Export.png"}
          ></img>
        </Grid>
        <Grid item md={7} sm={8}>
          <div style={{ backgroundColor: "#fff", position: "relative", width: "100%" }}>
            {!this.props.isUserVerified && (
              <div style={{ height: "100vh", width: "100%" }}>
                <div id="auth-container" className="reset-form" ></div>
              </div>
            )}
          </div>
        </Grid>
        <Grid item md={12}>
          <footer className={classes.footer}>
            <p>&copy; 2021 Colgate-Palmolive Company All Rights Reserved</p>
            <div className="links">
              <a
                href="https://www.colgatepalmolive.com/en-us/legal-privacy-policy/terms-of-use"
                target="_blank"
                rel="noreferrer"
              >
                Terms of Service
              </a>
              <a
                href="https://www.colgatepalmolive.com/en-us/legal-privacy-policy"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
            </div>
          </footer>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(withStyles(styles)(PasswordReset));

/**
 * JWT functionality is being put into this class so that the underlying libraries
 * can be switched out at will without requiring code changes in other parts of
 * the application since they will use this class's interface.
 */

/**
 * Uses
 * https://github.com/auth0/node-jws
 * https://github.com/Brightspace/node-jwk-to-pem
 */
import jws from "jws";
import jwkToBuffer from "jwk-to-pem";
/**
 * Internal Keys Cache
 */
const keyCache = {};
/**
 * Default validation options
 */
const validationOptions = { expiration: true, signature: true };
/**
 * JWT Utility Class
 */
export class JWTToken {
  constructor(token) {
    this.token = token;
    this.decodedToken = jws.decode(token);
  }
  get payload() {
    if (!this.decodedToken || !this.decodedToken.payload) {
      return null;
    }
    return this.decodedToken.payload;
  }
  get header() {
    if (!this.decodedToken || !this.decodedToken.header) {
      return null;
    }
    return this.decodedToken.header;
  }
  get signature() {
    if (!this.decodedToken || !this.decodedToken.signature) {
      return null;
    }
    return this.decodedToken.signature;
  }
  /**
   * This method does NOT take into account leeway.
   * This is a strict comparison of the exp vs the current time.
   * Adapted from https://github.com/gustavo0197/react-jwt/blob/master/src/jwt/index.ts
   */
  get isExpired() {
    const payload = this.payload;
    if (!payload || !payload.exp) {
      return true;
    }
    const exp = payload.exp;
    const expirationDate = new Date(0);
    expirationDate.setUTCSeconds(exp); // sets the expiration seconds
    // compare the expiration time and the current time
    return expirationDate.valueOf() < new Date().valueOf();
  }

  /**
   * Get the JWT as a JSON object.
   */
  get decoded() {
    return this.decodedToken;
  }
  /**
   * Allows for decoding with specific options.
   */
  decode(options = {}) {
    return jws.decode(this.token, options);
  }
  /**
   *
   * @param {Number} secondsFromNow Will the token expire in this number of seconds?
   * @returns Boolean
   */

  isAboutToExpire(secondsFromNow = 60) {
    const payload = this.payload;
    if (this.isExpired || !payload || !payload.exp) {
      return true;
    }
    const exp = payload.exp;
    const expirationDate = new Date(0);
    expirationDate.setUTCSeconds(exp);
    const millisecondsFromNow = secondsFromNow * 1000;
    const leewayDate = new Date(Date.now() + millisecondsFromNow);
    return leewayDate.valueOf() >= expirationDate.valueOf();
  }

  getKeyFromCache() {
    const header = this.header;
    let key = null;
    if (header) {
      key = keyCache[header.kid];
      if (key) {
        return key;
      }
    }
    return null;
  }
  /**
   * Get the public signing key.
   * Check cache first and if not found call out to server to get keys.
   */
  async getSingingKey() {
    let key = this.getKeyFromCache();
    if (key) {
      return key;
    }

    const gigya = window.gigya;
    const keyUrl = `https://accounts.${gigya.dataCenter}.${gigya.defaultApiDomain}/accounts.getJWTPublicKey?apiKey=${gigya.apiKey}&v2=true`;
    const keys = await fetch(keyUrl).then((response) => response.json());
    for (const k of keys.keys) {
      keyCache[k.kid] = k;
    }
    return this.getKeyFromCache();
  }
  /**
   * Validate the token.
   * @param {Object} options Validation options. These will be merged with the defaults.
   * Options are set as booleans. E.g., signature:true. Valid options are:
   * expiration - default true
   * signature  - default true
   *
   */
  async isValid(options = {}) {
    const opts = { ...validationOptions, ...options };
    if (opts.expiration && this.isExpired) {
      return false;
    }
    if (opts.signature) {
      let key = await this.getSingingKey();
      if (!key) {
        console.error(
          "jwt.isValid: key not in cache and could not be retrieved from server."
        );
        return false;
      }
      const header = this.header;
      if (!header) {
        console.error("jwt.isValid: Unable to get header.");
        return false;
      }
      // Public jwk needs to be converted to pem format for validation to work.
      const pem = jwkToBuffer(key);
      if (!pem) {
        console.error("jwt.isValid: Unable to create pem from public key.");
      }
      // The docs for this method call the first parameter signature,
      // but you have to pass in the entire token.
      return jws.verify(this.token, header.alg, pem);
    }
    console.warn(
      "jwt.isValid: was called with options that did not validate anything."
    );
    return true;
  }
}

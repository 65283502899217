import React from "react";
import { Dialog, DialogContent } from "@material-ui/core";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import "./error.css";

export default function ErrorDialog(props) {
  const { open, message, onClose, title } = props;
  return (
    <Dialog open={open} onClose={onClose} className="cp-error-dialog">
      <DialogTitle>{title || "Error"}</DialogTitle>
      <DialogContent>
        <div className="cp-error-dialog-message">{message}</div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import React from "react";
import PatientsTable from "../../components/patients/patients.table";

class PatientsPage extends React.Component {
  render() {
    return (
      <div>
        <div>
          <PatientsTable />
        </div>
      </div>
    );
  }
}

export default PatientsPage;

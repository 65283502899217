/**
 * Programatically set themes and styles go here.
 */
import { createTheme } from "@material-ui/core";
export const theme = createTheme();
theme.typography.h5 = {
  fontFamily: "Roboto, sans-serif !important",
  fontSize: "1rem",
  [theme.breakpoints.up("1450")]: {
    fontSize: "1.125rem",
  },
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "21px",
  letterSpacing: "0px",
  textAlign: "left",
  color: "#525252",
};

theme.typography.h2 = {
  fontFamily: "Roboto, sans-serif !important",
  fontSize: "1rem !important",
  [theme.breakpoints.up("1450")]: {
    fontSize: "1.188rem !important",
  },
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "33.6px",
  letterSpacing: "0px",
  textAlign: "left",
  color: "#525252",
};
theme.typography.h6 = {
  fontFamily: "Roboto, sans-serif !important",
  fontSize: "1rem",
  [theme.breakpoints.up("1450")]: {
    fontSize: "1.125rem",
  },
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "21.09px",
  letterSpacing: "0px",
  textAlign: "left",
  color: "#2D2D2D",
};

export const styles = (theme) => ({
  patientdetails: {
    width: "100%",
    height: "100vh",
    boxSizing: "border-box",
    padding: "1vh 5vh 3vh 5vh",
    [theme.breakpoints.up("1500")]: {
      padding: "39px 184px 24px 184px",
    },
  },
  tabName: {
    textTransform: "none !important",
    fontSize: "20px !important",
    fontStyle: "Roboto, sans-serif !important",
    lineHeight: "28px !important",
    alignItems: "Left !important",
    verticalAlign: "Top !important",
    color: "#525252 !important",
  },
  mainTabs: {
    height: "30px",
    [theme.breakpoints.up("1450")]: {
      height: "10vh",
    },
    marginTop: "-8px",
    [theme.breakpoints.up("1450")]: {
      marginTop: "2vh",
    },
    marginBottom: "20px",
    [theme.breakpoints.up("1450")]: {
      marginBottom: "4vh",
    },
    "& .MuiTabs-indicator": {
      marginLeft: "8px",
    },
  },
  selected: {
    color: "#D2010D !important",
    textTransform: "none",
  },
  default: {
    color: "#818282 !important",
    textTransform: "none",
  },
  tab: {
    minWidth: 200, // a number of your choice
    width: 200, // a number of your choice
    height: 42,
  },
  divider: {
    border: "1px solid #C8C8C8",
    marginTop: "-1.6%",
    [theme.breakpoints.down("1300")]: {
      marginTop: "-2%",
    },
    [theme.breakpoints.up("1500")]: {
      marginTop: "-2.8%",
    },
  },
  titlebar: {
    paddingBottom: "4px",
    [theme.breakpoints.up("1450")]: {
      paddingBottom: "16px",
    },
    display: "flex",
  },
});

//https://stackoverflow.com/questions/55611631/reactjs-protected-route-with-api-call
//https://stackoverflow.com/questions/53959937/how-to-handle-async-request-in-react-router-wrapper
import { Component } from "react";
import { Redirect, Route } from "react-router-dom";
import appModel from "../../models/app";

class ProtectedRoute extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      authenticated: false,
    };
    const jwt = appModel.jwtToken;
    jwt.isValid().then((valid) => {
      this.setState(() => ({ isLoading: false, authenticated: valid }));
    });
  }

  render() {
    if (this.state.isLoading) {
      return <div>Loading...</div>;
    } else {
      if (this.state.authenticated) {
        return (
          <div>
            <Route
              path={this.props.path}
              component={this.props.component}
              exact={this.props.exact}
            />
          </div>
        );
      } else {
        return (
          <Redirect
            to={{ pathname: "/", state: { from: this.props.location } }}
          />
        );
      }
    }
  }
}
export default ProtectedRoute;

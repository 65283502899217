import React, { Component } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
} from "react-router-dom";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import TagManager from 'react-gtm-module'
import HomePage from "./views/homepage";
import PatientsPage from "./views/patients/patients";
import PatientDetailsPage from "./views/patients/patient/details";
import PatientAddPage from "./views/patients/patient/add";
import DentistDetail from "./views/dentist";
import PasswordReset from "./views/passwordreset";
import appModel from "./models/app";
import ProtectedRoute from "./components/routes/protected";
import IdleTimer from "react-idle-timer";
import IdleTimeOutModal from "./components/idletimer/dialog";
import NavbarComponent from "./Navbar";
import Dexie from "dexie";

const theme = createTheme({
  palette: {
    primary: {
      main: "#e23023",
    },
    secondary: {
      main: "#408188",
    },
  },
});
// Used to monitor JWT expiration
let monitorJWTInterval = null;
// idle timer auto logout
const AUTO_LOGOUT_AFTER = 60; // in seconds
let secondsUntilAutoLogoutInterval = null;


const tagManagerArgs = {
  gtmId: 'GTM-MDRHCZN'
}

TagManager.initialize(tagManagerArgs)


class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userName: appModel.userName,
      anchorEl: null,
      isUserVerified: appModel.isLoggedIn,
      idleTimeOut: 1800000, //30 minutes
      showIdleDialog: false
    };
    this.idleTimer = null;
    this.setAnchorEl = this.setAnchorEl.bind(this);
    this.updateUserName = this.updateUserName.bind(this);
    this.handleUserMenuClosed = this.handleUserMenuClosed.bind(this);
    this.handleLogOut = this.handleLogOut.bind(this);
    this.handleUserMenuOpened = this.handleUserMenuOpened.bind(this);
    this.onLoginResponse = this.onLoginResponse.bind(this);
    this.getAuthToken = this.getAuthToken.bind(this);
    this.getAuthTokenCallback = this.handleAuthTokenResponse.bind(this);
    this.checkJWTExpiration = this.checkJWTExpiration.bind(this);
    this.onIdle = this.onIdle.bind(this);
    this.handleIdleDialogClose = this.handleIdleDialogClose.bind(this);
    this.clearAutoLogoutInterval = this.clearAutoLogoutInterval.bind(this);
    this.onUserProfileUpdated = this.onUserProfileUpdated.bind(this);
  }
  // Idle timer handler
  onIdle(event) {
    this.idleTimer.pause();
    this.setState({
      showIdleDialog: true
    });
  }
  clearAutoLogoutInterval() {
    if (secondsUntilAutoLogoutInterval) {
      clearInterval(secondsUntilAutoLogoutInterval);
      secondsUntilAutoLogoutInterval = null;
    }
  }
  handleIdleDialogClose() {
    this.clearAutoLogoutInterval();
    this.idleTimer.start();
    this.setState({ showIdleDialog: false });
  }
  // component lifecycle hooks
  componentDidMount() {
    if (appModel.isLoggedIn) {
      this.startMonitoringJWT(); // handle page refresh
      this.idleTimer.start();
    }
  }
  // instance methods
  setAnchorEl(newVal) {
    this.setState({
      anchorEl: newVal,
    });
  }

  updateUserName(newValue) {
    appModel.userName = newValue;
    this.setState({
      userName: newValue,
    });
  }
//Logout function, delete db here
  handleLogOut() {
    Dexie.delete('patientDetails');
    this.clearAutoLogoutInterval();
    this.updateUserName(null);
    this.idleTimer.pause();
    appModel.clear();
    this.stopMonitoringJWT();
    this.setState({ showIdleDialog: false });
    // Using window.location causes a new http request that history.push
    // does not. This should address the case of when the logout happens on the
    // home page causing the page to reload.
    window.location.href = "/";
  }

  handleUserMenuClosed() {
    this.setAnchorEl(null);
  }

  handleUserMenuOpened(event) {
    this.setAnchorEl(event.currentTarget);
  }
  handleAuthTokenResponse(response) {
    if (response && response.errorCode === 0) {
      appModel.accessToken = response.id_token;
      return true;
    } else {
      console.error("Unable to get JWT for user.");
      return false;
    }
  }
  getAuthToken(callback) {
    window.gigya.accounts.getJWT({
      callback: callback,
      fields: "email",
    });
  }

  onLoginResponse(response) {
    //Clear potential previous user's brushing data before new user login
    Dexie.delete('patientDetails');
    this.setState({ isUserVerified: response.isUserVerified });
    if (response.isVerified) {
      this.getAuthToken((tokenResponse) => {
        if (this.handleAuthTokenResponse(tokenResponse)) {
          this.startMonitoringJWT();
          this.idleTimer.start();
          var name =
            response.profile.firstName + " " + response.profile.lastName;
          this.updateUserName(name);
          this.props.history.push("/patients");
        }
      });
    }
  }
  onUserProfileUpdated(response) {
    if (response.profile) {
      this.updateUserName(
        response.profile.firstName + " " + response.profile.lastName
      );
    }
  }
  checkJWTExpiration() {
    const jwt = appModel.jwtToken;
    const expiring = jwt.isAboutToExpire();
    if (expiring) {
      this.getAuthToken((response) => {
        // If we could not get a new token, logout.
        if (!this.handleAuthTokenResponse(response)) {
          this.handleLogOut();
        }
      });
    }
  }
  startMonitoringJWT() {
    this.stopMonitoringJWT(); // do this to make sure we are not orphaning intervals.
    monitorJWTInterval = setInterval(this.checkJWTExpiration, 60000); // check every minute
  }
  stopMonitoringJWT() {
    if (monitorJWTInterval) {
      clearInterval(monitorJWTInterval);
      monitorJWTInterval = null;
    }
  }

  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          element={document}
          onIdle={this.onIdle}
          debounce={250}
          timeout={this.state.idleTimeOut}
          startManually={true}
        />
        <IdleTimeOutModal
          show={this.state.showIdleDialog}
          handleClose={this.handleIdleDialogClose}
          handleLogout={this.handleLogOut}
          secondsUntilLogout={AUTO_LOGOUT_AFTER}
        />
        {this.state.userName !== null  && (
          <NavbarComponent onUserProfileUpdated={this.onUserProfileUpdated} 
                           handleLogOut={this.handleLogOut}
          />
        )}
        <Router>
          <Switch>
            <Route exact path="/">
              <HomePage
                onLoginResponse={this.onLoginResponse}
                isUserVerified={this.state.isUserVerified}
              />
            </Route>
            <ProtectedRoute path="/patients" component={PatientsPage} />
            <ProtectedRoute
              path="/patient/details"
              component={() => (
                <PatientDetailsPage showErrorDialog={this.showErrorDialog} />
              )}
            />
            <ProtectedRoute path="/patient/add" component={PatientAddPage} />
            <ProtectedRoute
              path="/dentist/update"
              component={() => (
                <DentistDetail updateUser={this.updateUserName} />
              )}
            />
            <Route exact path="/dentist/reset">
              <PasswordReset />
            </Route>
          </Switch>
        </Router>
      </MuiThemeProvider>
    );
  }
}

export default withRouter(App);
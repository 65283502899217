import axios from "axios";
import moment from "moment";

const headers = { Authorization: "", serial: "" };

export default class KolibreeService {
  constructor() {
    this.url = process.env.REACT_APP_KL_BACKEND_URL;
  }
  set authToken(value) {
    headers["Authorization"] = `Bearer ${value}`;
  }
  set serial(value) {
    headers["serial"] = value.toLowerCase();
  }
  async getBrushingsForAccount(accountID, beginDate = null) {
    if (!beginDate) beginDate = new Date();
    beginDate.setMonth(beginDate.getMonth() - 12);
    const brushings = await this._get(`v1/accounts/${accountID}/brushings/`, {
      pd: true,
      begin_date: moment(beginDate).format("YYYY-MM-DD"),
    });
    while (brushings.data.total_count > brushings.data.brushings.length) {
      const additionalBrushings = await this._get(
        `v1/accounts/${accountID}/brushings/`,
        {
          pd: true,
          begin_date: moment(
            brushings.data.brushings.slice(-1)[0].datetime
          ).format("YYYY-MM-DD"),
        }
      );

      brushings.data.brushings = Array.from(
        new Set([
          ...brushings.data.brushings,
          ...additionalBrushings.data.brushings,
        ])
      );
    }

    return brushings;
  }

  async getBrushing(accountID, profileID, brushingID) {}

  async _get(path, params) {
    return axios.get(`${this.url}/${path}`, {
      headers: headers,
      params: params,
    });
  }
}

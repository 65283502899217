import { createTheme } from "@mui/material/styles";
export const theme = createTheme();

theme.typography.h6 = {
  fontSize: "87.5%",
  [theme.breakpoints.up("1450")]: {
    fontSize: "112.5%",
  },
  [theme.breakpoints.down("1000")]: {
    fontSize: "77%",
  },
  fontFamily: "Roboto, sans-serif !important",
  fontStyle: "Regular",
  fontWeight: "500",
  lineHeight: "25.2px",
  letterSpacing: "0px",
  textTransform: "none",
  textAlign: "left",
  color: "#818282",
};

theme.typography.h1 = {
  fontSize: "122%",
  [theme.breakpoints.up("1450")]: {
    fontSize: "200%",
  },
  fontFamily: "Roboto, sans-serif !important",
  fontStyle: "Medium",
  fontWeight: "500",
  letterSpacing: "0px",
  textAlign: "Center",
  verticalAlign: "Top",
  color: "#4A4A4A",
};

theme.typography.h5 = {
  fontSize: "87.5%",
  [theme.breakpoints.up("1450")]: {
    fontSize: "112.5%",
  },
  fontFamily: "Roboto, sans-serif !important",
  fontStyle: "Medium",
  fontWeight: "400",
  lineHeight: "25.2px",
  letterSpacing: "0px",
  textAlign: "Left",
  verticalAlign: "Top",
  color: "#818282",
};

theme.typography.h3 = {
  fontSize: "106.3%",
  [theme.breakpoints.up("1450")]: {
    fontSize: "125%",
  },
  [theme.breakpoints.down("1000")]: {
    fontSize: "83%",
  },
  fontFamily: "Roboto, sans-serif !important",
  fontStyle: "Normal",
  fontWeight: "400",
  lineHeight: "33.6px",
  color: "#2D2D2D",
};

theme.typography.subtitle1 = {
  fontSize: "87.5%",
  fontFamily: "Roboto, sans-serif !important",
  fontStyle: "Regular",
  fontWeight: "400",
  lineHeight: "16.8px",
  letterSpacing: "2px",
  textAlign: "Left",
  verticalAlign: "Top",
  color: "#818282",
};

export const styles = (theme) => ({
  patientdetails: {
    width: "100%",
    height: "100vh",
    boxSizing: "border-box",
  },
  datefilter: {
    display: "flex",
    paddingTop: "0.7rem",
    [theme.breakpoints.up("1500")]: {
      paddingTop: "2rem !important",
    },
    marginBottom: "0.5rem",
    [theme.breakpoints.up("1450")]: {
      marginBottom: "1.5rem",
    },
  },
  averagetab: {
    backgroundColor: "white",
    borderRadius: "8px !important",
    height: "11.91%",
    [theme.breakpoints.up("1450")]: {
      height: "8.75%",
    },
    paddingTop: "-1rem !important",
    paddingRight: "9%",
    paddingLeft: "1%",
    boxShadow: "0px 2px 6px rgba(200, 200, 200, 0.5) !important",
  },
  insidechart: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    [theme.breakpoints.up("1450")]: {
      marginTop: "1rem",
    },
  },
  graphtitle: {
    "& .MuiTabs-indicator": {
      marginBottom: "4px !important",
    },
    fontFamily: "Roboto, sans-serif !important",
    fontStyle: "Medium",
    fontSize: "16px !important",
    lineHeight: "22.4px !important",
    color: "#3D4551 !important",
    alignItems: "Center",
    verticalAlign: "Top",
    textTransform: "none !important",
    paddingLeft: "5%",
  },
  charttitle: {
    "& .MuiTabs-indicator": {
      marginBottom: "5px !important",
    },
    fontFamily: "Roboto, sans-serif !important",
    fontStyle: "Medium",
    fontSize: "16px !important",
    lineHeight: "22.4px !important",
    color: "#3D4551 !important",
    alignItems: "Center",
    verticalAlign: "center",
    textTransform: "none !important",
    paddingLeft: "2%",
    [theme.breakpoints.up("1450")]: {
      paddingLeft: "15%",
    },
    [theme.breakpoints.down("1750")]: {
      paddingLeft: "6%",
    },
    [theme.breakpoints.down("16220")]: {
      paddingLeft: "4%",
    },

  },
  dayweek: {
    "& .MuiTabs-indicator": {
      marginBottom: "5px !important",
      width: "20%"
    },
    fontFamily: "Roboto, sans-serif !important",
    fontStyle: "Medium",
    fontSize: "16px !important",
    lineHeight: "22.4px !important",
    color: "#3D4551 !important",
    verticalAlign: "Top",
    textTransform: "none !important",
  },
  lastdiv: {
    display: "flex",
    justifyContent: "center",
    marginTop: "-2rem",
    [theme.breakpoints.down("1450")]: {
      marginTop: "-1rem",
    },
    [theme.breakpoints.up("1500")]: {
      marginTop: "1rem",
    },
  },
  cardcontent: {
    [theme.breakpoints.up("1450")]: {
      marginTop: "-1rem",
    },
    paddingTop: 0,
    border: "none !important",
  },
  chartpanel: {
    borderRadius: "8px !important",
    marginTop: "-1.5rem",
    [theme.breakpoints.up("1450")]: {
      height: "250%",
    },
  },
  charttab: {
    borderRadius: "8px !important",
    marginBottom: "-25rem",
    [theme.breakpoints.up("1450")]: {
      marginBottom: "1.5rem",
    },
    marginTop: "0.3rem",
    height: "50%",
    [theme.breakpoints.up("1450")]: {
      marginTop: "1.5rem",
      height: "58%",
    },
    backgroundColor: "white",
  },
  gridtab: {
    display: "inline-block",
    verticalAlign: "middle",
  },
  selected: {
    color: "#D2010D !important",
    textTransform: "none",
  },
  default: {
    color: "#818282 !important",
    textTransform: "none",
  },
  totalChartArea: {
    marginTop: "1px",
    border: "none !important",
  },
  seeDatafor: {
    fontSize: "14px",
    [theme.breakpoints.up("1450")]: {
      fontSize: "18px",
    },
    fontFamily: "Roboto, sans-serif !important",
    fontStyle: "Normal",
    fontWeight: "400",
    lineHeight: "25px",
    textAlign: "Center",
    verticalAlign: "Center",
    color: "#4A4A4A",
    paddingTop: "-0.5rem",
  },
  OnaverageTag: {
    marginTop: "-28px",
    [theme.breakpoints.up("1450")]: {
      marginTop: "-55px",
    },
  },
  pastdays: {
    fontSize: "14px",
    [theme.breakpoints.up("1450")]: {
      fontSize: "16px",
    },
    fontFamily: "Roboto, sans-serif !important",
    lineHeight: "22px",
    fontStyle: "normal",
    alignItems: "Left",
    verticalAlign: "center",
    color: "#525252 !important",
  },
  tabsInsideChart: {
    paddingLeft: "8px",
    paddingRight: "8px",
    width: "34%",
    [theme.breakpoints.up("1450")]: {
      paddingLeft: "26px",
      paddingRight: "26px",
    },
    paddingTop: "0px",
    paddingBottom: "0px",
    minWidth: 0,
  },
  typeofgraph:{
    [theme.breakpoints.up("1450")]: {
      paddingLeft: "26px",
      paddingRight: "26px",
    },

    paddingTop: "0px",
    paddingBottom: "0px",
    minWidth: 0,
  },
  title: {
    paddingLeft: 0,
    [theme.breakpoints.up("1450")]: {
      paddingLeft: "32px",
    },
  },
  calendar: {
    paddingLeft: "2rem",
    display: "flex",
    direction: "row",
    columnGap: "20px",
    justifyContent: "space-between",
  },
  averageValue: {
    fontFamily: "Roboto, sans-serif",
    fontSize: "17px",
    [theme.breakpoints.up("1450")]: {
      marginTop: "20px",
    },
    lineHeight: "28px",
  },
  root: {
    borderRadius: "4px !important",
    width: "180px !important",
    height: "40px !important",
    background: "white !important",
  },
  refresh: {
    "&:hover": {
      backgroundColor: "#E5E5E5",
    },
  },
  divider: {
    border: "1px solid #C8C8C8",
    textTransform: "rotate(90deg)",
    height: "60px",
    [theme.breakpoints.down("1450")]: {
      height: "43px",
    },
  },
  selectbox: {
    width: "146px",
    [theme.breakpoints.up("1500")]: {
      width: "150px",
    },
    height: "1.7rem",
    [theme.breakpoints.up("1450")]: {
      height: "2.5rem",
    },
    marginLeft: "10px",
    background: "white",
    alignContent: "Inside",
    borderRadius: "100px",
  },
  zoombuttons: {
    display: "flex",
    direction: "row",
    justifyContent: "space-evenly",

    paddingLeft: 0,
    [theme.breakpoints.up("1450")]: {
      paddingLeft: "94px",
    },
  },
});

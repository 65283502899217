import React, { Component } from "react";
import { Grid, Box, Paper, Button, TextField } from "@mui/material";
import { withStyles } from "@material-ui/core/styles";
import ColgateService from "../../../services/colgate";
import { Note } from "../../../models/colgate/studies";
import { styled } from "@mui/material/styles";
import appModel from "../../../models/app";
import ErrorDialog from "../../../components/dialogs/error";
import MathUtils from "../../../utils/math";

const styles = (theme) => ({
  customField: {
    "&::placeholder": {
      fontSize: "14px",
      color: "#525252",
      fontFamily: "Roboto, sans-serif",
    },
    paddingTop: "10%",
  },
  inputProps: {
    fontSize: 14,
    borderRadius: "3.5px",
    fontFamily: "Roboto, sans-serif !important",
    width: "22vw",
    border: "0.5px solid gray",
    padding: "15px",
  },
  overall: {
    paddingTop: "2rem",
    [theme.breakpoints.down("1500")]: {
      paddingTop: "1rem",
    },
  },
  notes: {
    height: "73vh",
    color: "#2D2D2D",
    padding: "40px",
    [theme.breakpoints.down("1500")]: {
      paddingTop: "20px",
    },
    boxShadow: "0px 2px 6px rgba(200, 200, 200, 0.5)",
    borderRadius: "8px",
  },
  firstItem: {
    height: "73vh",
    boxShadow: "0px 2px 6px rgba(200, 200, 200, 0.5)",
    borderRadius: "8px",
  },
});

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  justifyContent: "space-evenly",
  direction: "row",
  color: theme.palette.text.secondary,
}));

class Recommendation extends Component {
  constructor(props) {
    super(props);
    this.service = new ColgateService(props?.brushSerialNumber);
    this.service.authToken = appModel.jwtToken.token;
    this.state = {
      inputCharactersCount: 0,
      hovering: false,
      clicking: false,
      recommendationToAdd: "",
      recommendations: [],
      errorDialogMessage: null,
      errorDialogTitle: "",
    };
    this.handleRecommendationChanged =
      this.handleRecommendationChanged.bind(this);
    this.handleSaveRecommendation = this.handleSaveRecommendation.bind(this);
  }
  handleRecommendationChanged(e) {
    this.setState({ inputCharactersCount: e.target.value.length });
    this.setState({ recommendationToAdd: e.target.value });
  }

  determineInputRows() {
    const minResolution = 540;
    const maxResolution = 2160; // 4K
    const minRows = 10;
    const maxRows = 45;
    let rows = MathUtils.linearMap(
      window.innerHeight,
      minResolution,
      maxResolution,
      minRows,
      maxRows
    );

    return Math.floor(rows);
  }

  determineButtonMarginRight() {
    const minResolution = 720;
    const maxResolution = 1920; // 1080p
    const minMarginShift = 0;
    const maxMarginShift = 14;
    let marginShift = MathUtils.linearMap(
      window.innerWidth,
      minResolution,
      maxResolution,
      minMarginShift,
      maxMarginShift
    );
    marginShift = MathUtils.clamp(marginShift, minMarginShift, maxMarginShift);
    return marginShift.toString() + "vw";
  }

  async handleSaveRecommendation(e, v) {
    let newRec = null;
    try {
      newRec = await this.service.createNote(
        new Note({ text: this.state.recommendationToAdd })
      );
    } catch (err) {
      console.warn(err);
      this.setState({
        errorDialogMessage: appModel.unableToSaveDataErrorMessage,
      });
      return;
    }
    let recs = [newRec, ...this.state.recommendations];
    this.setState({ recommendations: recs, recommendationToAdd: "" });
    this.setState({ inputCharactersCount: 0 });
    this.setState({ clicking: false });
  }

  async updateNotes() {
    if (!this.service.serial) return;
    let notes = null;
    try {
      notes = await this.service.getNotes();
    } catch (err) {
      console.error(err);
      this.setState({
        errorDialogMessage: appModel.unableToLoadDataErrorMessage,
      });
      return;
    }
    console.log(notes);
    this.setState({ recommendations: notes });
  }

  async componentDidMount() {
    await this.updateNotes();
  }

  async componentDidUpdate(old) {
    if (old.brushSerialNumber === this.props.brushSerialNumber) return;
    this.service.serial = this.props.brushSerialNumber;
    await this.updateNotes();
  }

  render() {
    const { classes } = this.props;

    return (
      <Box sx={{ flexGrow: 1 }} className={classes.overall}>
        <Grid container spacing={2}>
          <Grid item xs={4} style={{ margin: "0 0px 0 80px" }}>
            <Item className={classes.firstItem}>
              <div
                style={{
                  display: "flex",
                  flex: "1",
                  flexBasis: "auto",
                  alignItems: "baseline",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                <TextField
                  multiline
                  variant="standard"
                  minRows={this.determineInputRows()}
                  maxRows={this.determineInputRows()}
                  className={classes.customField}
                  onChange={this.handleRecommendationChanged}
                  placeholder="Type recommendation here"
                  inputProps={{ maxLength: 1000 }}
                  InputProps={{ className: classes.inputProps }}
                  value={this.state.recommendationToAdd}
                />
                <Item
                  fontSize="14px"
                  style={{
                    width: "22vw",
                    textAlign: "right",
                    color:
                      this.state.inputCharactersCount === 1000
                        ? "#D2010D"
                        : "#000000",
                  }}
                >
                  {this.state.inputCharactersCount.toString() + "/1000"}
                </Item>
                <Button
                  disabled={this.state.inputCharactersCount === 0}
                  variant={
                    this.state.inputCharactersCount === 0
                      ? "outlined"
                      : "contained"
                  }
                  style={{
                    textTransform: "none",
                    backgroundColor: this.state.clicking
                      ? "#B90000"
                      : this.state.hovering
                      ? "#DF4D56"
                      : "#D2010D",
                    opacity:
                      this.state.inputCharactersCount === 0 ? "40%" : "100%",
                    color: "white",
                    borderColor: "#D2010D",
                    borderRadius: "40px",
                    width: "182px",
                    float: "left",
                    fontFamily: "Roboto, sans-serif !important",
                    marginRight: this.determineButtonMarginRight(),
                  }}
                  onPointerEnter={() => {
                    this.setState({ hovering: true });
                  }}
                  onPointerLeave={() => {
                    this.setState({ hovering: false, clicking: false });
                  }}
                  onPointerDown={() => {
                    this.setState({ hovering: false, clicking: true });
                  }}
                  onPointerUp={this.handleSaveRecommendation}
                >
                  Add Recommendation
                </Button>
              </div>
            </Item>
          </Grid>
          <Grid item xs={6} style={{ margin: "0 0px 80px 0px" }}>
            <Item className={classes.notes}>
              <span
                style={{
                  fontFamily: "Roboto, sans-serif !important",
                  fontStyle: "normal",
                  fontWeight: "normal",
                  fontSize: "18px",
                  lineHeight: "20px",
                  color: "#2D2D2D",
                  float: "left",
                }}
              >
                Logged Recommendations
              </span>
              <div
                style={{
                  overflowX: "hidden",
                  overflowY: "scroll",
                  height: "101%",
                  width:
                    MathUtils.linearMap(
                      window.innerWidth,
                      720,
                      4096,
                      108,
                      102
                    ).toString() + "%",
                }}
              >
                {this.state.recommendations.length ? (
                  this.state.recommendations.map(function (rec, index) {
                    return (
                      <Grid
                        container
                        display="flex"
                        flexDirection="row"
                        flexWrap="nowrap"
                        style={{ padding: "40px 30px 0 0px" }}
                      >
                        <Grid item>
                          <span
                            style={{
                              fontFamily: "Roboto, sans-serif !important",
                              color: "#818282",
                              fontSize: "16px",
                            }}
                          >
                            {rec.createdAt.format("MMM D yy")}
                          </span>
                          <div>
                            <span
                              style={{
                                fontFamily: "Roboto, sans-serif !important",
                                color: "#818282",
                                fontSize: "16px",
                              }}
                            >
                              {rec.createdAt.format("hh:mm a")}
                            </span>
                          </div>
                        </Grid>
                        <Grid item style={{ marginLeft: "30px" }}>
                          <p
                            style={{
                              fontFamily: "Roboto, sans-serif !important",
                              color: "#2D2D2D",
                              fontSize: "14px",
                              textAlign: "left",
                              width: "25vw",
                            }}
                            key={rec.ID}
                          >
                            {rec.text}
                          </p>
                        </Grid>
                      </Grid>
                    );
                  })
                ) : (
                  <div
                    style={{
                      marginTop: "20vh",
                      marginLeft: "8vw",
                      backgroundColor: "#F4F5F6",
                      height: "200px",
                      width: "324px",
                      borderRadius: "1000px",
                      textAlign: "center",
                    }}
                  >
                    <p style={{ paddingTop: "80px" }}>
                      <span
                        style={{
                          fontFamily: "Roboto, sans-serif !important",
                          fontStyle: "regular",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "22.4px",
                          color: "#525252",
                        }}
                      >
                        No recommendation yet.<br></br> Add one to the Left.
                      </span>
                    </p>
                  </div>
                )}
              </div>
            </Item>
          </Grid>
        </Grid>
        <ErrorDialog
          message={this.state.errorDialogMessage}
          open={this.state.errorDialogMessage !== null}
          onClose={() => {
            this.setState({ errorDialogMessage: null });
          }}
        />
      </Box>
    );
  }
}

export default withStyles(styles)(Recommendation);

import { BaseAdapter } from "../base";
export class KolibreeBrushingAdpater extends BaseAdapter {
  constructor(src) {
    super(src);
    this.id = src.id;
    this.accountID = src.account_id;
    this.profileID = src.profile;
    this.dateString = src.associated_date;
    this.cleanScore = src.clean_score;
    this.coins = src.coins;
    this.datetimeString = src.datetime;
    this.duration = src.duration;
    this.coverage = src.initial_coverage;
    this.quality = src.quality;
    this.processedData = src.processed_data;
    this._datetime = null;
    this._setDates();
  }

  /**
   *  Set object datetime using _datetime, shifted to UTC equivalent.
   */
  _setDates() {
    this._datetime = new Date(
      this.datetimeString.substring(0, this.datetimeString.length - 5)
    );
    this._datetime.setTime(
      this._datetime.getTime() - new Date().getTimezoneOffset() * 60 * 1000
    );
    this.offset = this.datetimeString.substring(this.datetimeString.length - 5);
  }
}

import moment from "moment";

class Note {
  constructor(data) {
    this.text = data.text;
    this.ID = data.id;
    this.UserID = data.user_id;
    this.createdAt = moment.utc(data.created_at).local();
    this.createdBy = data.created_by;
  }

  get Post() {
    return {
      user_id: this.UserID,
      text: this.text,
      created_by: this.createdBy,
    };
  }
}

class User {
  constructor(data) {
    this.accountID = data.account_id;
    this.studyID = data.study_id;
    this.brushID = data.brush_id;
    this.ID = data.id;
    this.latestNote = new Note(data.latest_note || {});
    this.active = data.active;
  }

  get Post() {
    return {
      account_id: this.accountID,
      study_id: this.studyID,
      brush_id: this.brushID,
      active: this.active,
    };
  }
}
export { User, Note };

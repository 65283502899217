import PersistentModel from "./persistent";
import brushingDataPM from "../views/patients/patient/brushingData.pm";
import { JWTToken } from "../utils/jwt";
// Key constants for getting and setting data in local storage.
const SELECTED_PATIENT_KEY = "selected_patient";
const ACCESS_TOKEN_KEY = "access_token";
const USER_NAME_KEY = "user_name";

// Top level model for the application.
class AppModel extends PersistentModel {
  get selectedPatient() {
    return JSON.parse(this.get(SELECTED_PATIENT_KEY));
  }
  set selectedPatient(value) {
    this.set(SELECTED_PATIENT_KEY, JSON.stringify(value));
    brushingDataPM.restoreDefaults();
  }
  get accessToken() {
    return this.get(ACCESS_TOKEN_KEY);
  }
  set accessToken(value) {
    this.set(ACCESS_TOKEN_KEY, value);
  }
  get jwtToken() {
    return new JWTToken(this.accessToken);
  }
  get userName() {
    return this.get(USER_NAME_KEY);
  }
  set userName(value) {
    this.set(USER_NAME_KEY, value);
  }
  get isLoggedIn() {
    return !this.jwtToken.isExpired;
  }
  get unableToSaveDataErrorMessage() {
    return `Unable to save changes. Please try again. ${this.contactTechSupportMessage}`;
  }
  get unableToLoadDataErrorMessage() {
    return `Unable to load data for the selected user. ${this.refreshPageMessage} ${this.contactTechSupportMessage}`;
  }
  get refreshPageMessage() {
    return "Please refresh the page and try again.";
  }
  get contactTechSupportMessage() {
    return `If this problem persists, please contact tech support at ${this.contactUsEmail}.`;
  }
  get contactUsEmail() {
    return "colgateconnectpro@colpal.com";
  }
  get emailNotWhitelistedMessage() {
    return `Your email needs to be approved to use this application. Please contact us at ${this.contactUsEmail}.`;
  }
}

const appModel = new AppModel();
export default appModel;

import React from "react";
import "./homepage.css";
import PropTypes from "prop-types";
import { withRouter, Redirect } from "react-router-dom";
import { styled } from "@mui/material/styles";
import appModel from "../models/app";
import { Grid, Paper } from "@mui/material";
import { withStyles } from "@material-ui/core/styles";
import ColgateService from "../services/colgate";
import EmailValidator from "../validators/email";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const styles = (theme) => ({
  connectPro: {
    position: "relative",
    left: "13vw",
    bottom: "60vh",
    marginRight: "75%",
    [theme.breakpoints.down("xl")]: {
      width: "40%",
    },
  },
  colgateLogo: {
    position: "relative",
    bottom: "80vh",
    marginRight: "0%",
    [theme.breakpoints.down("xl")]: {
      width: "20%",
    },
  },
  footer: {
    backgroundColor: "#811C1A",
    color: "#ffff",
    position: "absolute",
    fontFamily: "Roboto, sans-serif",
    fontSize: "11px",
    lineHeight: "19px",
    left: 0,
    bottom: "-9px",
    right: 0,
    paddingTop: "5px",
    [theme.breakpoints.up("1500")]: {
      paddingTop: "10px",
      bottom: "0px",
      fontSize: "16px",
    },
  },
});
//constants
const GIGYA_LOGIN_FORM_SCREEN_NAME = "Pro_Portal_Login";
class HomePage extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };
  constructor(props) {
    super(props);
    /**
     * The Gigya fieldUpdated event can be fired multiple times,
     * this variable is used to not fire off multiple service
     * calls is one is already in progress.
     */
    this.validatingEmail = false;
  }

  componentDidMount() {
    window.gigya.accounts.showScreenSet({
      screenSet: "Pro-Portal-RegistrationLogin",
      startScreen: "Pro_Portal_Login",
      containerID: "auth-container",
      onAfterSubmit: ({ response }) => {
        this.props.onLoginResponse(response);
      },
      onBeforeScreenLoad: (event) => {
        /* 
        For now only clearing on login screen as not sure what side effects 
        clearing it for every screen set would have. 
        */
        if (event.nextScreen === GIGYA_LOGIN_FORM_SCREEN_NAME) {
          appModel.clear();
        }
      },
      onBeforeValidation: async (event) => {
        const email = event.formData.loginID || event.formData.email;
        if (EmailValidator.isValid(email)) {
          try {
            const service = new ColgateService();
            await service.validateEmail(email);
          } catch (error) {
            console.error(error);
            return {
              email: appModel.emailNotWhitelistedMessage,
              loginID: appModel.emailNotWhitelistedMessage,
              form: "Please check your email",
            };
          }
        }
      },
    });
  }

  render() {
    if (appModel.isLoggedIn) {
      return (
        <Redirect
          to={{ pathname: "/patients", state: { from: this.props.location } }}
        />
      );
    }
    const { classes } = this.props;
    return (
      <Grid container spacing={0} style={{ margin: "0" }}>
        <Grid item xs={5} className="overall-homepage">
          <Item>
            <img
              alt="simple-img"
              src={process.env.PUBLIC_URL + "/LoginImage.png"}
              style={{ height: "97vh", width: "100%", objectFit: "fill" }}
            ></img>
            <img
              alt="connectpro"
              className={classes.connectPro}
              src={process.env.PUBLIC_URL + "/ConnectPro.png"}
            ></img>
            <img
              alt="colgate_logo"
              className={classes.colgateLogo}
              src={process.env.PUBLIC_URL + "/Logo _Export.png"}
            ></img>
          </Item>
        </Grid>
        <Grid item xs={7} className="overall-homepage" style={{backgroundColor: "white !important"}}>
          {!this.props.isUserVerified && (
            <Item
              id="auth-container"
              className="login-signup-form"
              style={{backgroundColor: "white", overflowY: "scroll"}}
            ></Item>
          )}
        </Grid>
        <Grid item md={12} xs={12} className="overall-homepage">
          <footer className={classes.footer}>
            <p>&copy; 2021 Colgate-Palmolive Company All Rights Reserved</p>
            <div className="links">
              <a
                href="https://www.colgatepalmolive.com/en-us/legal-privacy-policy/terms-of-use"
                target="_blank"
                rel="noreferrer"
              >
                Terms of Service
              </a>
              <a
                href="https://www.colgatepalmolive.com/en-us/legal-privacy-policy"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
            </div>
          </footer>
        </Grid>
        <Grid item>
          <img
            alt="connectpro"
            className="tabletmode"
            src={process.env.PUBLIC_URL + "/TabletRotate.png"}
          ></img>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(withStyles(styles)(HomePage));

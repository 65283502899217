export default class MathUtils {
  static roundToDecimalPlaces = (value, precision) => {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  };

  static linearMap = (currentValue, minCurrentRange, maxCurrentRange, 
                     minNewRange, maxNewRange) => {
    return (
      minNewRange +
      ((currentValue - minCurrentRange) * (maxNewRange - minNewRange)) /
        (maxCurrentRange - minCurrentRange));
  }

  static clamp = (value, min, max) => {
    if (value >= max) {
      return max;
    } else if (value <= min) {
      return min;
    } else {
      return value;
    }
  }
}

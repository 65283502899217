/**
 * Allows extending classes to save to local storage
 * while providing getters and setters for convenience.
 */
export default class PersistentModel {
  get(key) {
    return localStorage.getItem(key);
  }
  set(key, value) {
    localStorage.setItem(key, value);
  }
  delete(key) {
    localStorage.removeItem(key);
  }
  clear() {
    localStorage.clear();
  }
  remove(key) {
    localStorage.removeItem(key);
  }
}

import React from "react";
import "../../../components/forms/centeredform.css";
import "../../../components/forms/formbase.css";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import { Grid, Card, CardContent, CardActions } from "@material-ui/core";
import { withRouter } from "react-router-dom";
// load patients data JSON file. This will be injected into the application from Vault during the deployment process.
const patientRows = require('../../../data/patients.json')
class PatientAddPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addedRows: [],
      patientId: "",
      brushingSerialnumber: "",
      patientStatus: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangePatientId = this.handleChangePatientId.bind(this);
    this.handleChangeSerialNumber = this.handleChangeSerialNumber.bind(this);
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
  }
  handleSubmit(event) {
    var added = patientRows.push({
      patientID: this.state.patientId,
      brushSerialNumber: this.state.brushingSerialnumber,
      patientStatus: this.state.patientStatus,
      compliantStatus: "compliant",
      brushingDate: "09-01-2021",
      brushingTime: "07:58 AM",
      frequency: 0,
      duration: 0,
      coverage: 0,
    });
    this.setState({ addedRows: added });
    this.props.history.push({
      pathname: "/patients",
      state: this.state.addedRows,
    });
  }
  handleChangePatientId(event) {
    this.setState({ patientId: event.target.value });
  }
  handleChangeSerialNumber(event) {
    this.setState({ brushingSerialnumber: event.target.value });
  }
  handleChangeStatus(event) {
    this.setState({ patientStatus: event.target.value });
  }

  render() {
    return (
      <div>
        <div style={{ paddingTop: "25px", paddingLeft: "100px" }}>
          <h1 style={{ color: "#8F1F1D" }}> Add New Patients </h1>
          <Card
            variant="outlined"
            style={{ width: "500px", borderRadius: "15px", padding: "39px" }}
          >
            <CardContent>
              <Grid container justify="center">
                <Grid item xs={12}>
                  <TextField
                    id="outlined-full-width"
                    label="*Patient ID"
                    style={{ margin: 8, width: "455px" }}
                    margin="normal"
                    value={this.state.patientId}
                    onChange={this.handleChangePatientId}
                    InputLabelProps={{
                      shrink: "true",
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="outlined-full-width"
                    label="*Brush Serial Number"
                    style={{ margin: 10, width: "455px" }}
                    margin="normal"
                    value={this.state.brushingSerialnumber}
                    onChange={this.handleChangeSerialNumber}
                    InputLabelProps={{
                      shrink: "true",
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="outlined-select-currency"
                    select
                    label="*Patient Status"
                    style={{ margin: 10, width: "455px" }}
                    value={this.state.patientStatus}
                    onChange={this.handleChangeStatus}
                    variant="outlined"
                  >
                    <MenuItem value={"Active"}>Active</MenuItem>
                    <MenuItem value={"Withdrawn"}>Withdrawn</MenuItem>
                  </TextField>
                </Grid>
              </Grid>

              <CardActions>
                <Button
                  className="top-right-corner-90-rest-rounded"
                  fullWidth
                  onClick={this.handleSubmit}
                >
                  Save Changes
                </Button>
              </CardActions>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }
}

export default withRouter(PatientAddPage);

export default class ArrayUtils {
  /**
   *
   * @param {Array} array
   */
  static average(array) {
    if (array.length > 0) {
      return array.reduce((a, b) => a + b) / array.length;
    }
    return null;
  }
}

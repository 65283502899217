import React from "react";
import { Line, Bar } from "react-chartjs-2";

const MultiTypeChartTypes = { BAR: "bar", LINE: "line" };

export default class MultiTypeChart extends React.Component {
  constructor(props) {
    super(props);
    // Need a reference to the charts so that we can
    // call update on it when the data changes.
    this.chart = React.createRef();
  }
  componentDidUpdate(oldProps) {
    if (this.chart.current) {
      this.chart.current.update();
    }
  }
  render() {
    // In the following key={Math.random()} is needed
    // to get the charts to rerender when the data changes.

    switch (this.props.type) {
      case MultiTypeChartTypes.BAR:
        return (
          <Bar
            key={Math.random()}
            ref={this.chart}
            data={this.props.data}
            options={this.props.options}
            width="200vw"
            height="400px"
          />
        );
      default:
        return (
          <Line
            key={Math.random()}
            ref={this.chart}
            data={this.props.data}
            width="60000px"
            height="400px"
            options={this.props.options}
          />
        );
    }
  }
}
export { MultiTypeChartTypes, MultiTypeChart };

const DAYS_DIVISOR = 1000 * 60 * 60 * 24;
export default class DateUtils {
  /**
   * Are two dates referring the the same date?
   * I.e., the day, month and year are the same.
   * @param {Date} a
   * @param {Date} b
   */
  static isSameDate(a, b) {
    return a.getTime() === b.getTime();
  }
  static inSameWeek(a, b) {
    if (a.getUTCFullYear() !== b.getUTCFullYear()) {
      return false;
    }
    return DateUtils.getWeekOfYear(a) === DateUtils.getWeekOfYear(b);
  }
  static inSameMonth(a, b) {
    if (a.getUTCFullYear() !== b.getUTCFullYear()) {
      return false;
    }
    return a.getUTCMonth() === b.getUTCMonth();
  }
  /**
   * Adapted from https://www.delftstack.com/howto/javascript/javascript-get-week-number
   * @param {Date} date
   * @returns
   */
  static getWeekOfYear(date) {
    var oneJan = new Date(date.getFullYear(), 0, 1);
    var numberOfDays = DateUtils.getDaysBetweenDates(date, oneJan);
    return Math.ceil((date.getDay() + 1 + numberOfDays) / 7);
  }
  /**
   * Get the number of days between two dates.
   */
  static getDaysBetweenDates(moreRecentDate, lessRecentDate) {
    return Math.round((moreRecentDate - lessRecentDate) / DAYS_DIVISOR);
  }
}

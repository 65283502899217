/**
 * Programatically set themes and styles go here.
 */
import { createTheme } from "@mui/material";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const theme = createTheme();
theme.typography.h2 = {
  fontSize: "1.25rem !important",
  [theme.breakpoints.up("1450")]: {
    fontSize: "1.5rem",
  },
  padding: "4vh 4vh 0 4vh",
  [theme.breakpoints.down("1450")]: {
    padding: "0vh 4vh 0 4vh",
  },
  fontWeight: "400",
};
theme.typography.body1 = {
  fontFamily: "Roboto, sans-serif !important",
  fontSize: "12px",
  [theme.breakpoints.up("1450")]: {
    fontSize: "16px",
  },
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "22px",
  letterSpacing: "0px",
  textAlign: "left",
};

theme.typography.h4 = {
  fontFamily: "Roboto, sans-serif !important",
  fontSize: "16px",
  [theme.breakpoints.up("1450")]: {
    fontSize: "20px",
  },
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "28px",
  letterSpacing: "0px",
  textAlign: "left",
  color: "#2D2D2D",
};

export const styles = {
  button: {
    marginTop: "50px",
    position: "left",
    textTransform: "none",
    width: "164px",
    height: "40px",
    borderRadius: "40px",
    backgroundColor: "#D2010D",
    color: "white",
    "&:hover": {
      background: "#D2010D",
    },
  },
  leftGrid: {
    backgroundColor: "#fff",
    marginBottom: "30vh",
    paddingTop: "7vh",
    borderRadius: "8px",
    boxShadow: "0px 2px 6px rgba(200, 200, 200, 0.5)",
    [theme.breakpoints.up("1450")]: {
      width: "766px",
      height: "896px",
    },
  },
  InputData: {
    paddingTop: "3px",
    [theme.breakpoints.up("1450")]: {
      paddingTop: "10px",
    },
  },
  dialogTitle: {
    fontSize: "1.25rem",
    [theme.breakpoints.up("1450")]: {
      fontSize: "1.5rem",
    },
    fontWeight: "400",
    color: "#000000",
    lineHeight: "33.6px",
    display: "flex",
  },
  addText: {
    "&.MuiInputBase-input": {
      padding: "17px 0 7px !important",
    },
  },
  patientlist: {
    backgroundColor: "white",
    border: "none",
    height: "98%",
    [theme.breakpoints.up("1450")]: {
      height: "91%"
    },
    padding: "21px 4vh 0 4vh",
  }
};

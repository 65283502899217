/**
 * Base class for burshing data filters.
 * This class is only being exported for type comparison purposes,
 * e.g., if(instance instanceOf BrushingDataFilter)
 * and is not meant to be instantiated directly.
 */
export class BrushingDataFilter {
  constructor(property = null) {
    this.property = property;
  }
  get label() {
    return `Brushing data filter for ${this.property}`;
  }
}
export class MinMaxFilter extends BrushingDataFilter {
  #min;
  #max;
  constructor(property = null, min = null, max = null) {
    super(property);
    this.min = min;
    this.max = max;
  }
  set min(value) {
    const m = parseInt(value);
    this.#min = isNaN(m) ? null : m;
  }
  get min() {
    return this.#min;
  }
  set max(value) {
    const m = parseInt(value);
    this.#max = isNaN(m) ? null : m;
  }
  get max() {
    return this.#max;
  }
  clear() {
    this.min = null;
    this.max = null;
  }
  get label() {
    return `${this.property}. Min ${this.min || "0"} to Max ${this.max || "0"}`;
  }
  get isValid() {
    return this.min !== null && this.max !== null;
  }
}

export class CoverageMinMaxFilter extends MinMaxFilter {
  constructor(min = 0, max = 0) {
    super("coverage", min, max);
  }
  get label() {
    return `${this.min || "0"} to ${this.max || "0"}`;
  }
}

export class DurationMinMax extends MinMaxFilter {
  constructor(min, max) {
    super("duration", min, max);
  }
  get label() {
    return `Duration: ${this.min || "0"} to ${this.max || "0"}`;
  }
}
/**
 * Duration min and max filter values are stored and applied in seconds.
 * The min[TimePeriod] properties allow these to be set from
 * more human friendly minutes and seconds values.
 *
 * See individual setters for calculations.
 *
 */
export class DurationMinMaxMinutesSecondsFilter extends DurationMinMax {
  #minMinutes;
  #minSeconds;
  #maxMinutes;
  #maxSeconds;
  constructor(
    minMinutes = null,
    minSeconds = null,
    maxMinutes = null,
    maxSeconds = null
  ) {
    super("duration");
    this.minMinutes = minMinutes;
    this.minSeconds = minSeconds;
    this.maxMinutes = maxMinutes;
    this.maxSeconds = maxSeconds;
  }
  get label() {
    let minMin = this.minMinutes;
    if (Number.isInteger(minMin) && minMin < 10) {
      minMin = "0" + minMin;
    }
    let minSec = this.minSeconds;
    if (Number.isInteger(minSec) && minSec < 10) {
      minSec = "0" + minSec;
    }
    let maxMin = this.maxMinutes;
    if (Number.isInteger(maxMin) && maxMin < 10) {
      maxMin = "0" + maxMin;
    }
    let maxSec = this.maxSeconds;
    if (Number.isInteger(maxSec) && maxSec < 10) {
      maxSec = "0" + maxSec;
    }
    return `${minMin || "00"}:${minSec || "00"} to ${maxMin || "00"}:${
      maxSec || "00"
    }`;
  }

  updateMinMax() {
    // min =====
    if (Number.isInteger(this.minMinutes) && this.minMinutes >= 0) {
      //conver minutes to seconds
      this.min = this.minMinutes * 60;
    }
    // if there is a value for min seconds add that.
    if (Number.isInteger(this.minSeconds) && this.minSeconds >= 0) {
      this.min += this.minSeconds;
    }
    // max =====
    if (Number.isInteger(this.maxMinutes) && this.maxMinutes >= 0) {
      //conver minutes to seconds
      this.max = this.maxMinutes * 60;
    }
    // if there is a value for max seconds add that.
    if (Number.isInteger(this.maxSeconds) && this.maxSeconds > 0) {
      this.max += this.maxSeconds;
    }
  }
  get minMinutes() {
    return this.#minMinutes;
  }
  set minMinutes(value) {
    const m = parseInt(value);
    this.#minMinutes = isNaN(m) ? null : m;
    if (this.minSeconds === null) {
      this.minSeconds = 0;
    }
    this.updateMinMax();
  }
  get minSeconds() {
    return this.#minSeconds;
  }
  set minSeconds(value) {
    const m = parseInt(value);
    this.#minSeconds = isNaN(m) ? null : m;
    if (this.minMinutes === null) {
      this.minMinutes = 0;
    }
    this.updateMinMax();
  }
  get maxMinutes() {
    return this.#maxMinutes;
  }
  set maxMinutes(value) {
    const m = parseInt(value);
    this.#maxMinutes = isNaN(m) ? null : m;
    if (this.maxSeconds === null) {
      this.maxSeconds = 0;
    }
    this.updateMinMax();
  }
  get maxSeconds() {
    return this.#maxSeconds;
  }
  set maxSeconds(value) {
    const m = parseInt(value);
    this.#maxSeconds = isNaN(m) ? null : m;
    if (this.maxMinutes === null) {
      this.maxMinutes = 0;
    }
    this.updateMinMax();
  }
  clear() {
    super.clear();
    this.minMinutes = null;
    this.minSeconds = null;
    this.maxMinutes = null;
    this.maxSeconds = null;
  }
}

import React from "react";

import "./homepage.css";

class DentistDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    window.gigya.accounts.showScreenSet({
      screenSet: "Pro-Portal-RegistrationLogin",
      startScreen: "Pro_Portal_ProfileUpdate",
      containerID: "edit-container",
      onAfterSubmit: ({ response }) => {
        if (this.props.onUserProfileUpdated) {
          this.props.onUserProfileUpdated(response);
        }
      },
    });
  }

  render() {
    return (
      <div style={{ height: "100% !important", width: "100% !important" }}>
        <div id="edit-container" className="login-edit-form"></div>
      </div>
    );
  }
}

export default DentistDetail;

import axios from "axios";
import { Note, User } from "../models/colgate/studies";

const headers = { Authorization: "" };

export default class ColgateService {
  constructor(serial) {
    this.url = process.env.REACT_APP_CH_BACKEND_URL;
    this.serial = serial;
  }

  set authToken(value) {
    headers["Authorization"] = `Bearer ${value}`;
  }

  async ping() {
    return this._get(`studies/users/ping`);
  }

  async getUser() {
    let userData = {};
    try {
      userData = await this._get(`studies/users/${this.serial}`);
    } catch (err) {
      if (err?.response?.status === 404) {
        return null;
      }
      throw err;
    }
    return new User(userData);
  }

  async createUser(user) {
    const res = await this._post(`studies/users`, user.Post);
    return new User(res.data);
  }

  async createNote(note) {
    const res = await this._post(
      `studies/users/${this.serial}/notes`,
      note.Post
    );
    return new Note(res.data);
  }

  async getNotes() {
    let user = await this.getUser();
    if (!user) {
      user = await this.createUser(new User({ brush_id: this.serial }));
    }
    const res = await this._get(`studies/users/${this.serial}/notes`);
    return res.data.notes.map((el) => new Note(el));
  }

  async validateEmail(email) {
    return axios.get(`${this.url}/studies/users/email/validate/${email}`, {
      headers: {
        "x-api-key": process.env.REACT_APP_GIGYA_KEY,
      },
    });
  }

  async _get(path, params) {
    return axios.get(`${this.url}/${path}`, { headers: headers });
  }

  async _post(path, params) {
    return axios.post(`${this.url}/${path}`, params, { headers: headers });
  }
}

export default class TimeUtil {
  static secondsToMinutesAndSeconds(seconds, prefixWithZero = true) {
    let min = Math.floor(seconds / 60);
    let sec = Math.round(seconds - min * 60);
    if (prefixWithZero) {
      if (min < 10) {
        min = "0" + min;
      }
      if (sec < 10) {
        sec = "0" + sec;
      }
    }
    return { minutes: min, seconds: sec };
  }
  static secondsToMinutesAndSecondsString(seconds) {
    const minsec = TimeUtil.secondsToMinutesAndSeconds(seconds, true);
    return `${minsec.minutes}:${minsec.seconds}`;
  }
}

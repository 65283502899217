import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { DataGrid } from "@mui/x-data-grid";
import { withStyles } from "@material-ui/core/styles";
import SearchBar from "../../components/SearchBar";
import appModel from "../../models/app";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  ThemeProvider,
  Box,
  Grid
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SortIcon from "@mui/icons-material/Sort";
import "./patients.table.css";
import { theme, styles, MenuProps } from "./patients.table.styles";
// load patients data JSON file. This will be injected into the application from Vault during the deployment process.
const patientRows = require("../../data/patients.json");
// sort patients by patient id descending
patientRows.sort((a, b) => (a.patientID > b.patientID) ? -1 : ((b.patientID > a.patientID) ? 1 : 0));

class PatientsTable extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showAddButton: false,
      poper: false,
      setSpacing: 0,
      search: "",
      columns: [{
        field: "patientID",
        headerName: "Patient ID",
        flex: 1,
      }, {
        field: "brushSerialNumber",
        headerName: "Brush Serial Number",
        flex: 1,
      }],
      rows: patientRows,
      unfilteredRows: patientRows,
      anchorEl: null,
      freq: null,
      dura: [],
      cover: [],
      data: 0,
      MenuProps,
      patientFocus: false,
      brushFocus: false,
    };
    this.doSearch = this.doSearch.bind(this);
    this.handlePatientSelected = this.handlePatientSelected.bind(this);
    this.handleNewPatient = this.handleNewPatient.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handlePatientInputFocus = () => {
    this.setState({ patientFocus: true });
  };
  handlePatientInputBlur = () => {
    this.setState({ patientFocus: false });
  };
  handleBrushInputFocus = () => {
    this.setState({ brushFocus: true });
  };
  handleBrushInputBlur = () => {
    this.setState({ brushFocus: false });
  };
  handleNewPatient(event) {
    this.setState({ poper: true });
  }
  handleClose(event, reason) {
    if (reason !== "backdropClick") {
      this.setState({ poper: false });
    }
  }
  handlePatientSelected(event) {
    appModel.selectedPatient = event.row;
    this.props.history.push("/patient/details");
  }
  doSearch(searchedVal) {
    if (searchedVal === "") {
      this.setState({ rows: patientRows });
    } else {
      const filteredRows = patientRows.filter((row) => {
        const searchFor = searchedVal.toString().toLowerCase();
        return (row.patientID
          .toString()
          .toLowerCase()
          .includes(searchFor) || row.brushSerialNumber
          .toString()
          .toLowerCase()
          .includes(searchFor));
      });
      this.setState({ rows: filteredRows });
    }
  }

  render() {
    const { classes } = this.props;
    return (<ThemeProvider theme={theme}>
      <Grid container spacing={2} direction="row" style={{
        margin: "7vh 80vh 150vh 25vh",
        height: "50vh",
      }}>
        <Grid item xs={7} md={5} className={classes.leftGrid} id="main-page">
          <Typography variant="h2">Patient List</Typography>
          <div style={{
            display: "flex",
            alignItems: "center",
          }}>
            <SearchBar value={this.state.search} className="search" style={{
              width: "200vh",
              height: "30px",
              margin: "3vh 4vh 0 4vh",
            }} onChange={(searchVal) => this.doSearch(searchVal)} onCancelSearch={() => this.doSearch("")} />
          </div>
          <DataGrid rows={this.state.rows} columns={this.state.columns} getRowId={(row) => row.accountID}
                    density="compact" rowsPerPageOptions={[]} disableColumnMenu onRowClick={this.handlePatientSelected}
                    components={{
                      ColumnSortedAscendingIcon: SortIcon,
                      ColumnSortedDescendingIcon: SortIcon,
                    }} hideFooterPagination className={classes.patientlist} />
        </Grid>
        <Grid item id="main-page" xs={5} md={3} style={{
          backgroundColor: "#fff",
          marginLeft: "5vh",
          marginBottom: "30vh",
          paddingTop: "4vh",
          borderRadius: "8px",
          boxShadow: "0px 2px 6px rgba(200, 200, 200, 0.5)",
        }}>
          <div style={{ paddingLeft: "20px" }}>
            <Typography variant="body1">Viewing</Typography>
            <div className={classes.InputData}>
              <Typography variant="h4">Verily Study Patients</Typography>
            </div>
          </div>
          <div style={{
            paddingTop: "20px",
            paddingLeft: "20px",
          }}>
            <Typography variant="body1">Brush Type</Typography>
            <div className={classes.InputData}>
              <Typography variant="h4">Pulse Brush</Typography>
            </div>
            {this.state.showAddButton === true ?
              <Button className={classes.button} variant="contained" onClick={this.handleNewPatient}>
                Add New Patient
              </Button> : null}
            <Dialog open={this.state.poper} onClose={this.handleClose} style={{ padding: "2.5rem 3rem 2.5rem 3rem" }}>
              <DialogTitle className={classes.dialogTitle} style={{ justifyContent: "space-between" }}>
                <Typography variant="h2" sx={{ padding: "0 !important" }}>Add New Patient</Typography>
                <CloseIcon onClick={this.handleClose} style={{ cursor: "pointer" }} />
              </DialogTitle>
              <DialogContent style={{ padding: "12px 41px !important" }}>
                <Box>
                  <TextField autoFocus margin="dense" label={this.state.patientFocus ? "Patient ID" : ""}
                             placeholder={this.state.patientFocus ? "" : "Patient ID"} fullWidth variant="outlined"
                             onFocus={this.handlePatientInputFocus} onBlur={this.handlePatientInputBlur} InputProps={{
                    style: {
                      paddingLeft: "16px",
                      paddingRight: "16px",
                    },
                  }} />
                  <TextField margin="dense" label={this.state.brushFocus ? "Brush Serial Number" : ""}
                             placeholder={this.state.brushFocus ? "" : "Brush Serial Number"} fullWidth
                             variant="outlined" onFocus={this.handleBrushInputFocus} onBlur={this.handleBrushInputBlur}
                             InputProps={{
                               style: {
                                 paddingLeft: "16px",
                                 paddingRight: "16px",
                               },
                             }} />
                  <Button className={classes.button} variant="contained" onClick={this.handleClose}
                          style={{ float: "right" }}>
                    Add Patient
                  </Button>
                </Box>
              </DialogContent>
            </Dialog>
          </div>
        </Grid>
        <Grid item className="tabletmodepatientlist">
          <img alt="connectpro" src={process.env.PUBLIC_URL + "/TabletRotate.png"}></img>
        </Grid>
      </Grid>
    </ThemeProvider>);
  }
}

export default withStyles(styles)(withRouter(PatientsTable));

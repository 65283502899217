import React, { Component } from "react";
import {
  Tab, Tabs, Divider, Select, MenuItem, Grid, Card, CardContent, TextField, CircularProgress,
} from "@mui/material";
import { withStyles } from "@material-ui/core/styles";
import TabPanel from "../../../components/tabs/panel";
import { MultiTypeChartTypes } from "../../../components/charts/multitype.chart";
import BarChartIcon from "@mui/icons-material/BarChart";
import TimelineIcon from "@mui/icons-material/Timeline";
import { Chart, Bar, Line } from "react-chartjs-2";
import zoomPlugin from "chartjs-plugin-zoom";
import { createTheme, ThemeProvider as MaterialThemeProvider } from "@material-ui/core/styles";
import { ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker, MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import ReplayIcon from "@mui/icons-material/Replay";
import { styles, theme } from "./brushingData.styles";
import ArrayUtils from "../../../utils/array";
import MathUtils from "../../../utils/math";
import TimeUtils from "../../../utils/time";
import brushingDataPM from "./brushingData.pm";
import moment from "moment";

const materialTheme = createTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "white",
      },
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        color: "black",
      },
      toolbarBtnSelected: {
        color: "black",
      },
    },
  },
});
Chart.register(zoomPlugin); // REGISTER PLUGIN
const createDefaultChartOptions = () => {
  return {
    maintainAspectRatio: false,
    animation: false,
    responsive: true,
    onHover: function(event) {
      event.native.target.style.cursor = "move";
    },
    plugins: {
      legend: {
        labels: {
          usePointStyle: true,
        },
      },
      zoom: {
        zoom: {
          mode: "x",
        },
        pan: {
          enabled: true,
          mode: "x",
        },
        limits: {
          x: {
            min: 0,
          },
          y: {
            min: 0,
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: false,
        },
      },
      y: {
        title: {
          display: true,
          text: "",
          font: {
            size: 16,
          },
        },
      },
    },
    elements: {
      point: {
        radius: 5,
      },
      line: {
        borderWidth: 1.5,
      },
    },
  };
};
const chartAMColor = "#FF9650";
const chartPMColor = "#4CBAC1";
const createChartData = () => {
  return {
    labels: [],
    datasets: [{
      label: "AM",
      data: [],
      fill: false,
      backgroundColor: chartAMColor,
      borderColor: chartAMColor,
    }, {
      label: "PM",
      data: [],
      fill: false,
      backgroundColor: chartPMColor,
      borderColor: chartPMColor,
    }],
  };
};
const createBrushingIntervalTemplate = () => {
  return {
    coverage: {
      am: 0,
      pm: 0,
      amBrushings: [],
      pmBrushings: [],
    },
    duration: {
      am: 0,
      pm: 0,
      amBrushings: [],
      pmBrushings: [],
    },
    frequency: {
      am: 0,
      pm: 0,
    },
  };
};
const addBrushingToChartBucket = (bucket, brushing, interval) => {
  /* Split into AM vs PM and calculate vales for that date. */
  if (brushing.isAM) {
    bucket.coverage.amBrushings.push(brushing.coverage);
    bucket.duration.amBrushings.push(brushing.duration);
    bucket.coverage.am = MathUtils.roundToDecimalPlaces(ArrayUtils.average(bucket.coverage.amBrushings), 1);
    bucket.duration.am = MathUtils.roundToDecimalPlaces(ArrayUtils.average(bucket.duration.amBrushings), 1);
    bucket.frequency.am = MathUtils.roundToDecimalPlaces(bucket.coverage.amBrushings.length / interval, 1);
  } else {
    bucket.coverage.pmBrushings.push(brushing.coverage);
    bucket.duration.pmBrushings.push(brushing.duration);
    bucket.coverage.pm = MathUtils.roundToDecimalPlaces(ArrayUtils.average(bucket.coverage.pmBrushings), 1);
    bucket.duration.pm = MathUtils.roundToDecimalPlaces(ArrayUtils.average(bucket.duration.pmBrushings), 1);
    bucket.frequency.pm = MathUtils.roundToDecimalPlaces(bucket.coverage.pmBrushings.length / interval, 1);
  }
};
/* 
The following two function create date objects that have been 
reset to either midnight or 1 second before midnight as dates
such as there are used in various filtering processes. 
Having these functions prevents of of having to do the math and 
resetting in multiple locations. 
*/
/**
 * Create a date object with it's hours minutes and seconds
 * reset to midnight of that date.
 * @param {Date} createFromDate
 * @returns {Date}
 */
const createFilterStartDate = (createFromDate = null) => {
  let date;
  if (createFromDate instanceof Date) {
    date = new Date(createFromDate);
  } else {
    date = new Date();
  }
  date.setHours(0, 0, 0, 0);
  return date;
};
/**
 * Create a date object with it's hours minutes and seconds
 * reset to one second before midnight of that date.
 * I.e., Right before it would switch to the next date.
 * @param {Date} createFromDate
 * @returns {Date}
 */
const createFilterEndDate = (createFromDate = null) => {
  let date;
  if (createFromDate instanceof Date) {
    date = new Date(createFromDate);
  } else {
    date = new Date();
  }
  date.setHours(23, 59, 59, 0);
  return date;
};
//constants
const CHART_NAME_COVERAGE = "chartNameCoverage";
const CHART_NAME_DURATION = "chartNameDuration";
const CHART_NAME_FREQUENCY = "chartNameFrequency";
const CHART_INTERVAL_MODE_DAY = "chartIntervalModeDay";
const CHART_INTERVAL_MODE_WEEK = "chartIntervalModeWeek";
const CHART_INTERVAL_MODE_MONTH = "chartIntervalModeMonth";

class BrushingData extends Component {
  constructor(props) {
    super(props);
    this.pm = brushingDataPM;
    this.chartRef = React.createRef();
    this.state = {
      chartIntervalMode: this.pm.chartIntervalMode || CHART_INTERVAL_MODE_DAY,
      chartsSelectedTab: this.pm.chartsSelectedTab,
      selectedChartName: this.pm.selectedChartName || CHART_NAME_COVERAGE,
      startDate: this.pm.startDate,
      endDate: this.pm.endDate,
      chartDisplayType: this.pm.chartDisplayType || MultiTypeChartTypes.BAR,
      frequencyChartData: null,
      coverageChartData: null,
      durationChartData: null,
      chartTitle: "Coverage Per Day",
      axisTitle: "Coverage (%)",
      brushingsFilterValue: this.pm.brushingsFilterValue,
      averageCoverage: 0,
      averageDuration: 0,
      averageFrequency: 0,
      filteredBrushings: this.props.brushings || [],
    };
    window.addEventListener("resize", this.update);
    this.handleChartSelected = this.handleChartSelected.bind(this);
    this.handleChartDisplayTypeSelected = this.handleChartDisplayTypeSelected.bind(this);
    this.filterBrushings = this.filterBrushings.bind(this);
    this.handleFilterDurationChanged = this.handleFilterDurationChanged.bind(this);
    this.resetZoomChart = this.resetZoomChart.bind(this);
    this.zoomButton = this.zoomButton.bind(this);
    this.switchChartDisplayType = this.switchChartDisplayType.bind(this);
    this.createChartTitle = this.createChartTitle.bind(this);
    this.onChartNameSelected = this.onChartNameSelected.bind(this);
    this.onChartIntervalModeChanged = this.onChartIntervalModeChanged.bind(this);
    this.createYAxisTitle = this.createYAxisTitle.bind(this);
    this.processFilteredBrushings = this.processFilteredBrushings.bind(this);
    this.handleChartIntervalModeChanged = this.handleChartIntervalModeChanged.bind(this);
    this.getTabValueForSelectedChartName = this.getTabValueForSelectedChartName.bind(this);
    this.getNameForSelectedChart = this.getNameForSelectedChart.bind(this);
  }

  /**
   * Needed to get the charts to repopulate when switching tabs.
   */
  componentDidMount() {
    this.filterBrushings();
    this.createChartTitle();
    this.createYAxisTitle();
  }

  /**
   * The brushings data is passed down from the parent component
   * since it is going to be used across multiple child components.
   * The data is loaded via an ajax call and is not avaiable in the
   * componentDidMount method which has been firing before the parent's
   * componentDidMount method. Hence, we watch for changes here and
   * update when the data changes.
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.brushings !== this.props.brushings) {
      this.filterBrushings();
    }
    //save state to model when it changes
    const pm = this.pm;
    const state = this.state;
    pm.chartsSelectedTab = state.chartsSelectedTab;
    pm.startDate = state.startDate;
    pm.endDate = state.endDate;
    pm.chartDisplayType = state.chartDisplayType;
    pm.brushingsFilterValue = state.brushingsFilterValue;
    pm.selectedChartName = state.selectedChartName;
  }

  processFilteredBrushings() {
    const brushings = this.state.filteredBrushings;
    const frequencyChartData = createChartData();
    const coverageChartData = createChartData();
    const durationChartData = createChartData();
    /* Averages collections. Used to later caclulate the averages.*/
    let coveragesSum = 0;
    let durationsSum = 0;
    if (brushings.length) {
      let startDate;
      let endDate;
      if (!(this.state.startDate instanceof Date) || !(this.state.endDate instanceof Date)) {
        startDate = createFilterStartDate();
        endDate = createFilterEndDate();
        startDate.setDate(this.pm.getDaysBetweenFilterDates(endDate));
      } else {
        startDate = createFilterStartDate(this.state.startDate);
        endDate = createFilterEndDate(this.state.endDate);
      }
      //sort brushings by date
      brushings.sort((a, b) => {
        return b.date - a.date;
      });
      /*
      Get the date of the most recent brushing. This date will be used to determine
      if we fill in a 0 value for the chart data to preserve continuity in the line
      charts between brushings dates, but display no data points for dates later than
      the most recent brushing.
      */
      const mostRecentBrushingDate = brushings[0].date;
      /*
      Hashmap for combining brushings by date.
      */
      const chartDataByInterval = {
        day: {},
        week: {},
        month: {},
      };
      /* b will be an instance of {KolibreeBrushingModel} */
      for (const b of brushings) {
        /*
         Use the date as a key to combine the data in a hashmap by date.
         */
        const dayKey = b.datetime.toLocaleDateString("en-US");
        const weekKey = `${moment(b.datetime).week()}-${b.datetime.getFullYear()}`;
        const monthKey = `${b.datetime.getMonth()}-${b.datetime.getFullYear()}`;
        if (!chartDataByInterval["day"][dayKey]) {
          chartDataByInterval["day"][dayKey] = createBrushingIntervalTemplate();
        }
        const daily = chartDataByInterval["day"][dayKey];
        if (!chartDataByInterval["week"][weekKey]) {
          chartDataByInterval["week"][weekKey] = createBrushingIntervalTemplate();
        }
        const weekly = chartDataByInterval["week"][weekKey];
        if (!chartDataByInterval["month"][monthKey]) {
          chartDataByInterval["month"][monthKey] = createBrushingIntervalTemplate();
        }
        const monthly = chartDataByInterval["month"][monthKey];
        addBrushingToChartBucket(daily, b, 1);
        addBrushingToChartBucket(weekly, b, 7);
        addBrushingToChartBucket(monthly, b, 30);
        coveragesSum += b.coverage;
        durationsSum += b.duration;
      }
      /*
      Averages:

      Frequency - Number of brushings during time period divided
      by days in period.

      Duration - Sum of durations divided by number of brushings.

      Coverage - Sum of covergaes divided by number of brushings.
      */
      const numberOfBrushings = brushings.length;
      /*
      When the filter value for the number of days is 0
      the division for avargage frequency end up with the value
      Infinity. If filtering days is not a positive number,
      return 0 for this average.
      */
      const numberOfDaysInFilter = this.pm.numberOfDaysInFilter;
      const averageFrequency = numberOfDaysInFilter > 0 ? numberOfBrushings / numberOfDaysInFilter : 0;
      const averageDuration = durationsSum / numberOfBrushings;
      const averageCoverage = coveragesSum / numberOfBrushings;
      /*
      Loop through the number of days and create chart data.
      Start with the most recent date and count backwards so
      that the most recent data is always included.
      */
      const chartIntervalMode = this.state.chartIntervalMode;
      while (endDate.getTime() >= startDate.getTime()) {
        /*
        Just grab a reference to the date that changes so that
        subsequent references do not need to be updated if it changes.
        */
        const keyDate = endDate;
        let chartLabel = moment(keyDate).format("MMM, DD YYYY");
        let key = "";
        let intervalKey = "day";
        switch (chartIntervalMode) {
          case CHART_INTERVAL_MODE_DAY:
            key = keyDate.toLocaleDateString("en-US");
            break;
          case CHART_INTERVAL_MODE_WEEK:
            key = `${moment(keyDate).week()}-${keyDate.getFullYear()}`;
            intervalKey = "week";
            break;
          case CHART_INTERVAL_MODE_MONTH:
            key = `${keyDate.getMonth()}-${keyDate.getFullYear()}`;
            intervalKey = "month";
            chartLabel = moment(keyDate).format("MMM, YYYY");
            break;
          default:
            chartLabel = "";
        }
        /*
        Since we count down from the most recent date.
        use unshift to get the desired date order from
        left (oldest) to right (newest)
         */
        coverageChartData.labels.unshift(chartLabel);
        durationChartData.labels.unshift(chartLabel);
        frequencyChartData.labels.unshift(chartLabel);
        //do we have a brushing for this date?
        const brushing = chartDataByInterval[intervalKey][key];
        if (brushing) {
          const coverage = brushing.coverage;
          const duration = brushing.duration;
          const frequency = brushing.frequency;
          // 0 = AM 1 = PM
          coverageChartData.datasets[0].data.unshift(coverage.am);
          durationChartData.datasets[0].data.unshift(duration.am);
          frequencyChartData.datasets[0].data.unshift(frequency.am);
          coverageChartData.datasets[1].data.unshift(coverage.pm);
          durationChartData.datasets[1].data.unshift(duration.pm);
          frequencyChartData.datasets[1].data.unshift(frequency.pm);
        } else {
          // 0 = AM 1 = PM
          if (keyDate.getTime() <= mostRecentBrushingDate.getTime()) {
            //fill in gaps between brushings with 0 values
            coverageChartData.datasets[0].data.unshift(0);
            durationChartData.datasets[0].data.unshift(0);
            frequencyChartData.datasets[0].data.unshift(0);
            coverageChartData.datasets[1].data.unshift(0);
            durationChartData.datasets[1].data.unshift(0);
            frequencyChartData.datasets[1].data.unshift(0);
          } else {
            //use nulls so the chart does not display a data points past the date of the last brushing.
            coverageChartData.datasets[0].data.unshift(null);
            durationChartData.datasets[0].data.unshift(null);
            frequencyChartData.datasets[0].data.unshift(null);
            coverageChartData.datasets[1].data.unshift(null);
            durationChartData.datasets[1].data.unshift(null);
            frequencyChartData.datasets[1].data.unshift(null);
          }
        }
        //count backwards from most recent date
        keyDate.setDate(keyDate.getDate() - this.getDaysForChartIntervalMode());
      }
      //set state to trigger chart render
      const durationAverageMinSec = TimeUtils.secondsToMinutesAndSeconds(Math.floor(averageDuration));
      this.setState({
        frequencyChartData: frequencyChartData,
        coverageChartData: coverageChartData,
        durationChartData: durationChartData,
        averageCoverage: Math.round(averageCoverage),
        averageDuration: `${durationAverageMinSec.minutes}:${durationAverageMinSec.seconds}`,
        averageFrequency: MathUtils.roundToDecimalPlaces(averageFrequency, 1),
      });
    } else {
      this.setState({
        frequencyChartData: null,
        coverageChartData: null,
        durationChartData: null,
        averageCoverage: 0,
        averageDuration: 0,
        averageFrequency: 0,
      });
    }
  }

  filterBrushings() {
    let brushings = this.props.brushings;
    if (brushings && brushings.length > 0) {
      let startDate = createFilterStartDate(this.state.startDate);
      let endDate = createFilterEndDate(this.state.endDate);
      if (startDate instanceof Date && endDate instanceof Date) {
        brushings = this.props.brushings.filter((b) => {
          const bTime = b.date.getTime();
          const sTime = startDate.getTime();
          const eTime = endDate.getTime();
          return bTime >= sTime && bTime <= eTime;
        });
      }
    }
    this.setState({ filteredBrushings: brushings }, this.processFilteredBrushings);
  }

  getDaysForChartIntervalMode(mode = this.state.chartIntervalMode) {
    switch (mode) {
      case CHART_INTERVAL_MODE_DAY:
        return 1;
      case CHART_INTERVAL_MODE_WEEK:
        return 7;
      case CHART_INTERVAL_MODE_MONTH:
        return 30;
      default:
        return 1; //default to days mode
    }
  }

  getTabValueForSelectedChartName(chartName = this.state.selectedChartName) {
    switch (chartName) {
      case CHART_NAME_COVERAGE:
        return 0;
      case CHART_NAME_DURATION:
        return 1;
      case CHART_NAME_FREQUENCY:
        return 2;
      default:
        return 0; //default to coverage
    }
  }

  getNameForSelectedChart(name = this.state.selectedChartName) {
    switch (name) {
      case CHART_NAME_COVERAGE:
        return "Coverage";
      case CHART_NAME_DURATION:
        return "Duration";
      case CHART_NAME_FREQUENCY:
        return "Frequency";
      default:
        return "Unknown";
    }
  }

  // Event handlers
  handleChartIntervalModeChanged(e, val) {
    this.setState({ chartIntervalMode: val }, this.processFilteredBrushings);
  }

  handleChartDisplayTypeSelected(e, v) {
    this.setState({ chartDisplayType: v });
  }

  handleChartSelected(value) {
    this.setState({
      selectedChartName: value,
      chartsSelectedTab: this.getTabValueForSelectedChartName(value),
    });
  }

  handleFilterDurationChanged(event) {
    const val = event.target.value;
    if (val !== 0) {
      /* In the following the state needs to be set in a certain order to get the
      correct cumputed values for the fitering dates.  */
      this.setState({
        brushingsFilterValue: val,
      }, () => {
        const startDate = new Date();
        const endDate = new Date();
        startDate.setDate(this.pm.getDaysBetweenFilterDates(endDate));
        this.setState({
          startDate: startDate,
          endDate: endDate,
        }, this.filterBrushings);
      });
    } else {
      this.setState({ brushingsFilterValue: 0 });
    }
    if (val === 90 || val === 30) {
      this.onChartIntervalModeChanged(event, CHART_INTERVAL_MODE_WEEK, val);
    } else if (val === 180) {
      this.onChartIntervalModeChanged(event, CHART_INTERVAL_MODE_MONTH, val);
    } else this.onChartIntervalModeChanged(event, CHART_INTERVAL_MODE_DAY, val);
  }

  onChartIntervalModeChanged(event, value) {
    this.setState({ chartIntervalMode: value }, () => {
      this.filterBrushings();
      this.createChartTitle();
      this.createYAxisTitle();
    });
  }

  createChartTitle() {
    const chartName = this.getNameForSelectedChart();
    const chartIntervalMode = this.state.chartIntervalMode;
    let title;
    switch (chartIntervalMode) {
      case CHART_INTERVAL_MODE_DAY:
        title = `${chartName} Per Day`;
        break;
      case CHART_INTERVAL_MODE_WEEK:
        title = `Average ${chartName} Per Week`;
        break;
      case CHART_INTERVAL_MODE_MONTH:
        title = `Average ${chartName} Per Month`;
        break;
      default:
        title = chartName;
    }
    this.setState({ chartTitle: title });
  }

  resetZoomChart() {
    this.chartRef.current.resetZoom();
  }

  zoomButton(event, value) {
    this.chartRef.current.zoom(value);
  }

  getChartWindowHeight() {
    if (window.innerWidth >= 1500 && window.innerHeight >= 800) {
      return "400px";
    } else if (window.innerWidth > 1280 && window.innerHeight >= 600) {
      return "280px";
    } else {
      return "230px";
    }
  }

  displayNoChartDataText() {
    if (this.props.loading) {
      return <div style={{
        backgroundColor: "#F4F5F6",
        height: this.getChartWindowHeight(),
        width: "100%",
        borderRadius: "25px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}>
        <CircularProgress />
      </div>;
    } else {
      return <div style={{
        backgroundColor: "#F4F5F6",
        height: this.getChartWindowHeight(),
        width: "100%",
        borderRadius: "25px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}>
        No Data Available for Selected Time Period </div>;
    }
  }

  switchChartDisplayType(type, data) {
    var title = this.state.axisTitle;
    const chartOptions = createDefaultChartOptions();
    chartOptions.scales.y.title.text = title;
    chartOptions.responsive = true;
    chartOptions.maintainAspectRatio = true;
    chartOptions.aspectRatio = this.calculateChartAspectRatio();
    /*
    Apply specific options based on the chart being displayed.
    */
    if (this.state.selectedChartName === CHART_NAME_DURATION) {
      chartOptions.scales.y.ticks = {
        callback: function(value) {
          return TimeUtils.secondsToMinutesAndSecondsString(value);
        },
      };
      chartOptions.plugins.tooltip = {
        callbacks: {
          label: function(context) {
            return `${context.dataset.label}: ${TimeUtils.secondsToMinutesAndSecondsString(context.raw)}`;
          },
        },
      };
    }
    switch (type) {
      case MultiTypeChartTypes.BAR:
        return (
          <Bar key={Math.random()} ref={this.chartRef} data={data} options={chartOptions} width="100%" height="28%" />);
      default:
        return (<Line key={Math.random()} ref={this.chartRef} data={data} options={chartOptions} width="100%"
                      height="28%" />);
    }
  }

  onChartNameSelected(event, value) {
    this.setState({
      selectedChartName: value,
      chartsSelectedTab: this.getTabValueForSelectedChartName(value),
    }, () => {
      this.createChartTitle();
      this.createYAxisTitle();
    });
  }

  createYAxisTitle() {
    const chartName = this.state.selectedChartName;
    const chartIntervalMode = this.state.chartIntervalMode;
    let title;
    if (chartIntervalMode === CHART_INTERVAL_MODE_DAY) {
      switch (chartName) {
        case CHART_NAME_COVERAGE:
          title = "Coverage (%)";
          break;
        case CHART_NAME_DURATION:
          title = "Duration (Minutes)";
          break;
        case CHART_NAME_FREQUENCY:
          title = "Times/Day";
          break;
        default:
          title = "";
      }
    } else {
      switch (chartName) {
        case CHART_NAME_COVERAGE:
          title = "Average Coverage (%)";
          break;
        case CHART_NAME_DURATION:
          title = "Average Duration (Minutes)";
          break;
        case CHART_NAME_FREQUENCY:
          title = "Average Times/Day";
          break;
        default:
          title = "";
      }
    }
    this.setState({ axisTitle: title });
  }

  calculateChartAspectRatio() {
    const widthBuffer = 48;
    const heightBuffer = 305;
    var currentRatio = (window.innerWidth - widthBuffer) / (window.innerHeight - heightBuffer);
    const minCurrentRatio = 0.451;
    const maxCurrentRatio = 3.75;
    const minNewRatio = 0.5;
    const maxNewRatio = 5;
    return MathUtils.linearMap(currentRatio, minCurrentRatio, maxCurrentRatio, minNewRatio, maxNewRatio);
  }

  render() {
    const { classes } = this.props;
    return (<ThemeProvider theme={theme}>
      <div className={classes.patientdetails}>
        <div className={classes.datefilter}>
          <Typography className={classes.seeDatafor}>See data for</Typography>
          <Select displayEmpty value={this.state.brushingsFilterValue} onChange={this.handleFilterDurationChanged}
                  className={classes.selectbox}>
            <MenuItem value={7}>
              <Typography className={classes.pastdays}>
                Past 7 Days
              </Typography>
            </MenuItem>
            <MenuItem value={30}>
              <Typography className={classes.pastdays}>Past Month</Typography>
            </MenuItem>
            <MenuItem value={90}>
              <Typography className={classes.pastdays}>
                Past 3 Months
              </Typography>
            </MenuItem>
            <MenuItem value={180}>
              <Typography className={classes.pastdays}>
                Past 6 Months
              </Typography>
            </MenuItem>
            <MenuItem value={0}>
              <Typography className={classes.pastdays}>
                Custom Date
              </Typography>
            </MenuItem>
          </Select>
          {this.state.brushingsFilterValue === 0 ? (<div className={classes.calendar}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <MaterialThemeProvider theme={materialTheme}>
                <KeyboardDatePicker label="Start Date" value={this.state.startDate} className={classes.root}
                                    variant="inline" size="small" autoOk inputVariant="outlined" format="MM/dd/yyyy"
                                    onChange={(newValue) => {
                                      this.setState({ startDate: newValue }, this.filterBrushings);
                                    }} renderinput={(params) => <TextField {...params} />} />
              </MaterialThemeProvider>
            </MuiPickersUtilsProvider>
            <br></br>
            <span style={{ marginTop: "0.7rem" }}> to</span>
            <br></br>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <MaterialThemeProvider theme={materialTheme}>
                <KeyboardDatePicker label="End Date" className={classes.root} value={this.state.endDate}
                                    variant="inline" size="small" autoOk inputVariant="outlined" format="MM/dd/yyyy"
                                    onChange={(newValue) => {
                                      this.setState({ endDate: newValue }, this.filterBrushings);
                                    }} renderInput={(params) => <TextField {...params} />}
                                    InputProps={{ className: classes.input }} />
              </MaterialThemeProvider>
            </MuiPickersUtilsProvider>
          </div>) : null}
        </div>
        <Grid container justifyContent="space-between" alignItems="center" className={classes.averagetab}>
          <Grid item className={classes.OnaverageTag}>
            <Typography variant="h5">
              {window.innerWidth > 1450 ? "On average..." : ""}
            </Typography>
          </Grid>
          <Grid item>
            {/*for coverage*/}
            <Typography variant="h1">
              {isNaN(this.state.averageCoverage) ? "Unknown" : this.state.averageCoverage}
              <span className={classes.averageValue}>%</span>
            </Typography>
            <div>
              <Typography variant="subtitle1">COVERAGE</Typography>
            </div>
          </Grid>
          <div>
            <Divider orientation="vertical" className={classes.divider} variant="middle" />
          </div>
          <Grid item>
            <Typography variant="h1">
              {this.state.averageDuration}
              <span className={classes.averageValue}> min</span>
            </Typography>
            <div>
              <Typography variant="subtitle1">DURATION</Typography>
            </div>
          </Grid>
          <div>
            <Divider className={classes.divider} orientation="vertical" variant="middle" />
          </div>
          <Grid item>
            <Typography variant="h1">
              {this.state.averageFrequency || 0}
              <span className={classes.averageValue}> times/day</span>
            </Typography>
            <div>
              <Typography variant="subtitle1">FREQUENCY</Typography>
            </div>
          </Grid>
        </Grid>
        <Grid container className={classes.charttab}>
          <Grid item xs={12} className={classes.charttab}>
            <Card className={classes.totalChartArea}>
              <CardContent className={classes.cardcontent}>
                <div className={classes.insidechart}>
                  <Typography variant="h3" onInput={this.inputChangeHandler} className={classes.title}>
                    {this.state.chartTitle}
                  </Typography>
                  <Tabs value={this.state.selectedChartName} onChange={this.onChartNameSelected}
                        className={classes.charttitle} TabIndicatorProps={{ style: { background: "#D2010D" } }}>
                    <Tab value={CHART_NAME_COVERAGE} className={classes.tabsInsideChart} label={<Typography variant="h6"
                                                                                                            className={this.state.chartsSelectedTab === 0 ? classes.selected : classes.default}>
                      Coverage
                    </Typography>} />
                    <Tab value={CHART_NAME_DURATION} className={classes.tabsInsideChart}
                         label={<Typography variant="h6" style={{ color: "#818282" }}
                                            className={this.state.chartsSelectedTab === 1 ? classes.selected : classes.default}>
                           Duration
                         </Typography>} />
                    <Tab value={CHART_NAME_FREQUENCY} className={classes.tabsInsideChart}
                         label={<Typography variant="h6" style={{ color: "#818282" }}
                                            className={this.state.chartsSelectedTab === 2 ? classes.selected : classes.default}>
                           Frequency
                         </Typography>} />
                  </Tabs>
                  <Tabs value={this.state.chartDisplayType} onChange={this.handleChartDisplayTypeSelected}
                        className={classes.graphtitle} TabIndicatorProps={{ style: { background: "#D2010D" } }}>
                    <Tab value={MultiTypeChartTypes.BAR} className={classes.typeofgraph} label={<Typography variant="h6"
                                                                                                            className={this.state.chartDisplayType === "bar" ? classes.selected : classes.default}>
                      <BarChartIcon style={{ verticalAlign: "middle" }} />
                      Bar
                    </Typography>} />
                    <Tab value={MultiTypeChartTypes.LINE} className={classes.typeofgraph}
                         label={<Typography variant="h6"
                                            className={this.state.chartDisplayType === "line" ? classes.selected : classes.default}>
                           <TimelineIcon style={{ verticalAlign: "middle" }} />
                           Line
                         </Typography>} />
                  </Tabs>
                  <div className={classes.zoombuttons}>
                    <Typography variant="h6" style={{
                      color: "#2D2D2D",
                      marginTop: "0.5rem",
                      marginRight: "1rem",
                    }}>
                      Zoom
                    </Typography>
                    <div style={{ marginTop: "0.3rem" }}>
                      <img alt="simple-img" src={process.env.PUBLIC_URL + "/AddIcon.png"} style={{
                        height: "2rem",
                        width: "2rem",
                        cursor: "pointer",
                      }} onClick={(event) => {
                        this.zoomButton(event, 1.1);
                      }}></img>
                      <img alt="simple-img" src={process.env.PUBLIC_URL + "/smallDivider.png"}
                           style={{ height: "2rem" }}></img>
                      <img alt="simple-img" src={process.env.PUBLIC_URL + "/RemoveIcon.png"} style={{
                        height: "2rem",
                        width: "2rem",
                        cursor: "pointer",
                      }} onClick={(event) => {
                        this.zoomButton(event, 0.9);
                      }}></img>
                      <ReplayIcon className={classes.refresh} sx={{
                        color: "#525252",
                        cursor: "pointer",
                        height: "2rem",
                        borderRadius: "3px",
                        width: "2rem",
                        background: "#F3F5F6",
                        marginLeft: "0.3rem",
                      }} onClick={this.resetZoomChart} />
                    </div>
                  </div>
                </div>
                <div>
                  <TabPanel className={classes.chartpanel} value={this.state.chartsSelectedTab} index={0}
                            onChange={this.handleChartSelected}>
                    {this.state.coverageChartData !== null ? this.switchChartDisplayType(this.state.chartDisplayType, this.state.coverageChartData) : this.displayNoChartDataText()}
                  </TabPanel>
                  <TabPanel className={classes.chartpanel} value={this.state.chartsSelectedTab} index={1}
                            onChange={this.handleChartSelected}>
                    {this.state.durationChartData !== null ? this.switchChartDisplayType(this.state.chartDisplayType, this.state.durationChartData) : this.displayNoChartDataText()}
                  </TabPanel>
                  <TabPanel className={classes.chartpanel} value={this.state.chartsSelectedTab} index={2}
                            onChange={this.handleChartSelected}>
                    {this.state.frequencyChartData !== null ? this.switchChartDisplayType(this.state.chartDisplayType, this.state.frequencyChartData) : this.displayNoChartDataText()}
                  </TabPanel>
                </div>
                <div className={classes.lastdiv}>
                  <Tabs className={classes.dayweek} value={this.state.chartIntervalMode}
                        onChange={this.onChartIntervalModeChanged}
                        TabIndicatorProps={{ style: { background: "#D2010D" } }}>
                    <Tab value={CHART_INTERVAL_MODE_DAY} className={classes.dayweektab} label={<Typography variant="h6"
                                                                                                           className={this.state.chartIntervalMode === CHART_INTERVAL_MODE_DAY ? classes.selected : classes.default}>
                      Day
                    </Typography>} />
                    {this.state.brushingsFilterValue !== 7 ? (
                      <Tab value={CHART_INTERVAL_MODE_WEEK} className={classes.dayweektab}
                           label={<Typography variant="h6"
                                              className={this.state.chartIntervalMode === CHART_INTERVAL_MODE_WEEK ? classes.selected : classes.default}>
                             Week
                           </Typography>} />) : null}
                    {this.state.brushingsFilterValue !== 7 && this.state.brushingsFilterValue !== 30 ? (
                      <Tab value={CHART_INTERVAL_MODE_MONTH} className={classes.dayweektab}
                           label={<Typography variant="h6"
                                              className={this.state.chartIntervalMode === CHART_INTERVAL_MODE_MONTH ? classes.selected : classes.default}>
                             Month
                           </Typography>} />) : null}
                  </Tabs>
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </ThemeProvider>);
  }
}

export default withStyles(styles)(BrushingData);

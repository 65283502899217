import { createTheme } from "@mui/material/styles";
export const theme = createTheme();

theme.typography.h3 = {
  fontSize: "1.05rem",
  [theme.breakpoints.up("1450")]: {
    fontSize: "1.5rem",
  },
  lineHeight: "34px",
  padding: "1.5rem 1rem 0 1.5rem",
  [theme.breakpoints.down("1500")]: {
    padding: "1.5rem 1rem 0 0.5rem",
  },
  marginTop: "-1.5rem",
  [theme.breakpoints.up("1500")]: {
    marginTop: "0rem",
  },
  fontWeight: "400",
  textAlign: "Left",
  fontFamily: "Roboto, sans-serif !important",
  color: "#2D2D2D",
};
theme.typography.h5 = {
  fontSize: "16px",
  [theme.breakpoints.up("1450")]: {
    fontSize: "18px",
  },
  lineHeight: "28px",
  padding: "0 4vh 0 4vh",
  fontWeight: "400",
  textAlign: "Left",
  fontStyle: "normal",
  fontFamily: "Roboto, sans-serif !important",
  color: "#2D2D2D",
};
theme.typography.subtitle1 = {
  fontSize: "14px",
  fontFamily: "Roboto, sans-serif !important",
  fontStyle: "Regular",
  fontWeight: "400",
  lineHeight: "16.8px",
  textAlign: "Left",
  verticalAlign: "Top",
  color: "#D2010D",
};

export const styles = (theme) => ({
  mouthmapdate: {
    fontFamily: "Roboto, sans-serif !important",
    fontStyle: "Regular",
    alignItems: "Center",
    verticalAlign: "Top",
    lineHeight: "28px",
    fontSize: "13px",
    color: "#000000",
  },
  mouthmapcover: {
    fontFamily: "Roboto, sans-serif !important",
    fontSize: "20px",
    [theme.breakpoints.up("1450")]: {
      fontSize: "32px",
    },
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "45px",
    textAlign: "center",
    color: "#2D2D2D",
  },
  mouthcovertitle: {
    fontSize: "11px",
    [theme.breakpoints.up("1450")]: {
      fontSize: "14px",
    },
    fontFamily: "Roboto, sans-serif !important",
    lineHeight: "16.8px",
    fontWeight: "400",
    fontStyle: "Normal",
    letterSpacing: "2px",
    color: "#818282",
  },
  mouthmapcard: {
    height: "58.9vh",
    [theme.breakpoints.up("1450")]: {
      height: "56.5vh",
    },
    border: "none !important",
    boxShadow: "none !important",
    marginTop: "-0.5rem",
    [theme.breakpoints.up("1450")]: {
      marginTop: "3rem",
    },
    [theme.breakpoints.down("1500")]: {
      marginTop: "0rem",
    },
  },
  customTextField: {
    "& input::placeholder": {
      fontSize: "14px",
      fontFamily: "Roboto, sans-serif !important",
      fontStyle: "Regular",
      align: "Left",
      verticalAlign: "Center",
      lineHeight: "19.6px",
      color: "#525252",
    },
    borderRadius: "40px !important",
    border: "1px solid #C8C8C8",
    boxSizing: "border-box",
  },
  tablesection: {
    height: "56vh",
    [theme.breakpoints.up("1450")]: {
      height: "80vh",
    },
    width: "100%",
    paddingLeft: "1rem",
    [theme.breakpoints.down("1450")]: {
      paddingLeft: "0.5rem",
    },
    paddingRight: "2rem",
    paddingTop: "2rem",
    [theme.breakpoints.down("1450")]: {
      paddingTop: "1rem",
    },
  },
  firstsection: {
    display: "flex",
    justifyContent: "flex-start",
    marginLeft: "-0.8rem",
    paddingTop: "0.7rem",
    [theme.breakpoints.up("1450")]: {
      paddingTop: "1rem",
      marginLeft: "-1rem",
    },
    width: "100%",
  },
  secondCard: {
    paddingTop: "0.5rem",
    [theme.breakpoints.up("1450")]: {
      paddingTop: "1.5rem",
    },
    marginLeft: "-0.05rem",
  },
  declaration: {
    borderBottom: "none !important",
  },
  tableData: {
    backgroundColor: "white",
    cursor: "pointer",
    border: "none",
    height: "90%",
    [theme.breakpoints.up("1450")]: {
      paddingTop: "2rem",
    },
    marginTop: "1.3rem",
    paddingLeft: "0rem",
    [theme.breakpoints.up("1450")]: {
      paddingLeft: "1rem",
    },
    borderBottom: "none !important",
  },
  overAllPage: {
    paddingTop: "1rem",
    [theme.breakpoints.up("1450")]: {
      paddingTop: "2rem",
    },
    height: "100vh",
  },
  selected: {
    backgroundColor: "white !important",
    boxShadow:
      "0px 0px 2px rgba(0, 0, 0, 0.1), 0px 2px 2px rgba(0, 0, 0, 0.08), 0px 1px 3px rgba(0, 0, 0, 0.16)",
    borderRadius: "8px",
  },

  selectbox: {
    width: "130px",
    [theme.breakpoints.up("1450")]: {
      width: "150px",
    },
    height: "1.7rem",
    [theme.breakpoints.up("1450")]: {
      height: "2.5rem",
    },
    marginLeft: "10px",
    background: "white",
    alignContent: "Inside",
    borderRadius: "100px",
  },
    clearButton:{
      cursor: "pointer",
      textTransform: "none",
      borderRadius: "40px",
      height: "32px",
      color: "#D2010D",
      '&:hover': {
        backgroundColor: '#FDF2F3',

    }},
    cardpage:{
      paddingTop:"5%",
      [theme.breakpoints.down("1280")]: {
        paddingTop:"20%",
      },
    }
});

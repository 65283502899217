import React, { Component } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import { Link } from "react-router-dom";
import Menu from "@material-ui/core/Menu";
import { withStyles } from "@material-ui/core/styles";
import { Typography, createTheme, ThemeProvider } from "@material-ui/core";
import appModel from "./models/app";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Dialog, DialogContent } from "@material-ui/core";
import DentistDetail from "./views/dentist";
import CloseIcon from "@mui/icons-material/Close";

const theme = createTheme();

theme.typography.h2 = {
  fontFamily: "Roboto, sans-serif !important",
  fontSize: "16px !important",
  [theme.breakpoints.up("1450")]: {
    fontSize: "24px !important",
  },
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "33.36px",
};

const styles = (theme) => ({
  title: {
    fontSize: "18px",
    [theme.breakpoints.up("1450")]: {
      fontSize: "24px",
    },
    alignItems: "Left",
    verticalAlign: "top",
    letterSpacing: "2px",
    color: "#525252",
    fontFamily: "ColgateReady,sans-serif",
    fontStyle: "Regular",
  },
  appheader: {
    backgroundColor: "#FFFFFF",
    flex: "0 0 32px",
    height: "20px",
    [theme.breakpoints.down("1100")]: {
      height: "40px",
    },
    [theme.breakpoints.up("1450")]: {
      flex: "0 0 40px",
      height: "80px",
    },
    fontSize: "1rem",
    display: "flex",
    direction: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 10px",
    boxShadow:
      "0px 2px 6px rgb(0 0 0 / 10%), 0px 4px 8px rgb(0 0 0 / 5%), 0px 6px 10px rgb(0 0 0 / 2%)",
  },
  username: {
    fontFamily: "Roboto, sans-serif",
    fontSize: "24px",
    [theme.breakpoints.down("1450")]: {
      fontSize: "18px",
    },
    color: "#2D2D2D",
    lineHeight: "33.6px",
  },
  logout: {
    fontFamily: "Roboto, sans-serif",
    fontStyle: "Regular",
    lineHeight: "33.6px",
    fontSize: "24px",
    [theme.breakpoints.down("1450")]: {
      fontSize: "18px",
    },
    color: "#D2010D",
  },
});

class NavbarComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userloggedIn: appModel.isLoggedIn,
      userName: appModel.userName,
      anchorEl: null,
      setOpen: false,
    };
    this.setAnchorEl = this.setAnchorEl.bind(this);
    this.handlUserMenuClosed = this.handlUserMenuClosed.bind(this);
    this.handleLogOut = this.handleLogOut.bind(this);
    this.handlUserMenuOpened = this.handlUserMenuOpened.bind(this);
    this.handleEditProfile = this.handleEditProfile.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  setAnchorEl(newVal) {
    this.setState({
      anchorEl: newVal,
    });
  }

  handleLogOut() {
    this.setState({
      userloggedIn: false,
      userName: "",
    });
    //If there is a handler defined in props, call it:
    if (this.props.handleLogOut) {
      this.props.handleLogOut();
    }
  }

  handlUserMenuClosed() {
    this.setAnchorEl(null);
  }

  handlUserMenuOpened(event) {
    this.setAnchorEl(event.currentTarget);
  }

  handleClose(event) {
    this.setState({ setOpen: false });
  }

  handleEditProfile(event) {
    this.setState({ setOpen: true });
  }

  render() {
    const open = Boolean(this.state.anchorEl);
    const { classes } = this.props;

    return (
      <ThemeProvider theme={theme}>
        <div className="nav-bar">
          <header className={classes.appheader}>
            <div className="header-logo-and-nav">
              <img
                alt="Colgate-Palmolive Logo"
                className="header-logo"
                src={process.env.PUBLIC_URL + "/Logo.png"}
              />
              <Link to="/patients" className="header-logo-link">
                <Typography className={classes.title}>ConnectPro</Typography>
              </Link>
            </div>

            {appModel.isLoggedIn && (
              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "25px", marginTop: "4px" }}>
                  <Typography variant="h2" style={{ color: "#2D2D2D" }}>
                    {appModel.userName}
                  </Typography>
                </div>
                <div style={{ marginTop: "4px", marginRight: "15px" }}>
                  <Typography
                    variant="h2"
                    onClick={this.handleLogOut}
                    style={{ color: "#D2010D", cursor: "pointer" }}
                  >
                    Log out
                  </Typography>
                </div>
                <div>
                  <MoreVertIcon
                    style={{
                      marginTop: "10px",
                      color: "#525252",
                      cursor: "pointer",
                    }}
                    onClick={this.handlUserMenuOpened}
                  />

                  <Menu
                    id="menu-appbar"
                    anchorEl={this.state.anchorEl}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={open}
                    onClose={this.handlUserMenuClosed}
                  >
                    <MenuItem
                      component="button"
                      onClick={this.handleEditProfile}
                      sx={{ marginRight: "38px" }}
                    >
                      Edit Profile
                    </MenuItem>
                    <Dialog
                      open={this.state.setOpen}
                      onClose={this.handleClose}
                      fullWidth={true}
                      sx={{
                        height: "100vh !important",
                        "&.MuiDialog-paperFullWidth": { height: "100%" },
                      }}
                    >
                      <DialogContent>
                        <CloseIcon
                          onClick={this.handleClose}
                          style={{
                            cursor: "pointer",
                            display: "block",
                            marginLeft: "auto",
                          }}
                        />
                        <DentistDetail
                          onUserProfileUpdated={this.props.onUserProfileUpdated}
                        />
                      </DialogContent>
                    </Dialog>
                    <MenuItem
                      component="a"
                      href="https://www.colgatepalmolive.com/en-us/legal-privacy-policy/terms-of-use"
                      target="_blank"
                    >
                      Terms of Service
                    </MenuItem>
                    <MenuItem
                      component="a"
                      href="https://www.colgatepalmolive.com/en-us/legal-privacy-policy"
                      target="_blank"
                    >
                      Privacy Policy
                    </MenuItem>
                  </Menu>
                </div>
              </div>
            )}
          </header>
        </div>
      </ThemeProvider>
    );
  }
}
export default withStyles(styles)(NavbarComponent);
